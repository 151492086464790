<template>
<h3 class="margin-top-0 margin-bottom-30">Delivery Details</h3>
                <input type="hidden" name="_token" value="DWChGGb1f7P8UnRftN4auyRigPhijltAep9VwEUG">                
                <div class="row">
                  <div class="address-label" style="margin-left: 15px;">
                    <label><strong>Pre-Order Delivery Time </strong><span class="text-danger">*</span></label>
                    <div class="form-group">
                      <label for="radio-group">Lunch Time</label>
                      <div id="radio-group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-1" value="11:30am to 12:30pm">
                          <label class="form-check-label" for="radio-1">11:30am to 12:30pm</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-2" value="12:30pm to 1:30pm">
                          <label class="form-check-label" for="radio-2">12:30pm to 1:30pm</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-3" value="1:30pm to 2:30pm">
                          <label class="form-check-label" for="radio-3">1:30pm to 2:30pm</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="radio-group">Dinner Time</label>
                      <div id="radio-group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-4" value="5:30pm to 6:30pm">
                          <label class="form-check-label" for="radio-4">5:30pm to 6:30pm</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-5" value="6:30pm to 7:30pm ">
                          <label class="form-check-label" for="radio-5">6:30pm to 7:30pm </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" v-model="preordertime" name="radio-group" id="radio-6" value="7:30pm to 8:30pm">
                          <label class="form-check-label" for="radio-6">7:30pm to 8:30pm</label>
                        </div>
                      </div>
                    </div>
                   </div>

                    <div class="form-group col-sm-10 ">
                     
                        <!-- checkbox start -->
                             <div class="">
                                <div class="address-label d-flex justify-content-between">
                                    <label><strong>Address</strong> <span class="text-danger">*</span></label>
                                    <a id="add-new" title="Add new address" class="address-btn" data-toggle="modal" data-target="#exampleModal" :data-attr="url+'/address'"><i class="fas fa-plus"></i> Add New</a>
                                </div>
                                <div class="text-danger">
                                    
                                </div>
                                <div class="each-radio">
                                    <div class="">
                                        <input type="radio" :value="this.useraddress.id" onchange="deliveryAddress(this.useraddress.latitude,this.useraddress.longitude)" checked="" name="address" id="address">
                                    </div>
                                    <div class="label">
                                        <p class="m-0"><b>{{this.useraddress.label_name}}</b></p>
                                        <p class="m-0" id="addressstring">{{this.useraddress.address}}</p>
                                        <p class="m-0">Apartment :{{this.apartment}}</p>
                                    </div>
                                    <div class="ml-auto mb-auto double-action">
<!--                                         <a id="edit7" onclick="editBtn('7')" class="action-btn mr-3" :data-url="url+'/address/edit/7'" :data-attr="url+'/address-update/update/7'"><i class="fas fa-edit"></i></a>
                                        <a  data-toggle="modal" data-target="#Removeaddress"  class="action-btn"><i class="fas fa-trash-alt"></i></a> -->
                                    </div>
                                </div>
                                                            </div>
                                                <!-- collapse -->
                        <!-- checkbox end -->
                        
                    </div>
                    <div class="form-group col-sm-10">
                        <label><strong>Phone</strong> <span class="text-danger">*</span></label>
                        <input type="text" class="form-control  phone" placeholder="Phone" name="mobile" v-model="phone" onkeypress="validate(event)">
                                            </div>
                    <input type="hidden" name="total_delivery_charge" id="total_delivery_charge" value="70812">
                    <div class="form-group col-sm-6">
                        <label><strong>Payment Type </strong><span class="text-danger">*</span></label>
                        <select name="payment_type" id="payment_type" onchange="myPaymentFunction()" class="form-control  ">
                            <option value="5">Cash on delivery</option>
                            <option value="20">Pay with credit balance 285.65 USD</option>
                            <option value="15">Stripe</option>
                         </select>
                     </div>
                </div>

                <div class="row no-margin-row">
                    <div class="margin-bottom-30 form-group col-sm-6 stripe-payment-method-div" style="">
                        <label><strong>Credit or debit card</strong> <span class="text-danger">*</span></label>
                        <div id="card-element" class="StripeElement StripeElement--empty"><div class="__PrivateStripeElement" style="margin: 0px !important; padding: 0px !important; border: none !important; display: block !important; background: transparent !important; position: relative !important; opacity: 1 !important;"><iframe name="__privateStripeFrame4186" frameborder="0" allowtransparency="true" scrolling="no" role="presentation" allow="payment *" src="https://js.stripe.com/v3/elements-inner-card-02ab94ca22ee69cb9fc8edbd0cc2001e.html#wait=false&amp;mids[guid]=NA&amp;mids[muid]=c813b714-6f04-400b-a84f-8ec3141d0ec8d6b8fe&amp;mids[sid]=3290ab1e-82ab-4694-888a-b2da75a6bdcf3f7d06&amp;rtl=false&amp;componentName=card&amp;keyMode=test&amp;apiKey=pk_test_Kqmq6XXBwdoYJFLV1CSDnaxz&amp;referrer=https%3A%2F%2Fenxalata.com%2Fcheckout&amp;controllerId=__privateStripeController4181" title="Secure card payment input frame" style="border: none !important; margin: 0px !important; padding: 0px !important; width: 1px !important; min-width: 100% !important; overflow: hidden !important; display: block !important; user-select: none !important; transform: translate(0px) !important; color-scheme: light only !important; height: 16.8px;"></iframe><input class="__PrivateStripeElement-input" aria-hidden="true" aria-label=" " autocomplete="false" maxlength="1" style="border: none !important; display: block !important; position: absolute !important; height: 1px !important; top: -1px !important; left: 0px !important; padding: 0px !important; margin: 0px !important; width: 100% !important; opacity: 0 !important; background: transparent !important; pointer-events: none !important; font-size: 16px !important;"></div></div>
                        <div id="card-errors" class="text-danger" role="alert"></div>
                    </div>
              </div>
           <div class="row no-margin-row">
            <div class="col-lg-12 col-md-12 form-group margin-bottom-65 d-flex">
               <div class="row no-margin-row">
                <div class="col-lg-12 col-md-12 form-group margin-bottom-65 d-flex">
                  <a href="https://enxalata.com/restaurant/enxalata" class="button continueBtn">CONTINUE</a>
                  <button type="submit" @click.prevent="order()" class="button booking-confirmation-btn">PLACE ORDER</button>
                </div>
               </div>
            </div>
        </div>

   <div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog custom-modal" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Address</h5>
                    <button id="modalClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="h1">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <form>
                            <input id="formMethod" type="hidden" name="_method" value="POST">
                            <div class="modal-body">
                                <div class="main-search-input-item location">
                                    <div id="autocomplete-container">
                                        <input id="autocomplete-input"  class="form-control address" name="new_address" type="text" placeholder="Search">
                                    </div>
                                    <a class="main-search-icon" href="javascript:void(0)">
                                        <i class="im im-icon-Location" id="locationIcon" onclick="getLocation()"></i>
                                    </a>
                                </div>
                                <input type="hidden" name="lat" id="lat" value="">
                                <input type="hidden" name="long" id="long" value="">
                                <input type="hidden" name="id" id="id" value="">
                                <div class="">
                                    <div id="googleMap" class="custom-map"></div>
                                </div>
                                <div class="form-group mt-4">
                                    <label>Apartment</label>
                                    <input id="apartment" type="text" class="form-control" placeholder="Apartment" name="apartment" v-model="apartment">
                                </div>
                                <label>Select Label</label>

                                <select name="label" id="label">
                                    <option value="" disabled selected>Select Label</option>
                                    
                                    <option>Home</option>
                                    <option>Work</option>
                                    <option>Other</option>
                                  
                                </select>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-lg btn-danger" data-dismiss="modal">Close</button>
                                <button @click="submit()" type="button" class="btn btn-lg btn-success" id="address-btn">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="Removeaddress" role="dialog">
                <div class="modal-dialog">
                
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">REMOVE YOUR ADDRESS?</h4>
                    </div>
                    <div class="modal-body">
                      <p>Are you sure you would like to remove   your address?</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal" style="padding: 10px; border: 1px solid #000;border-radius: 30px;width: 150px; font-size: 16px;">Cancel</button>
                      <button  type="button" class="btn btn-default" @click="deleteaddress()" style="padding: 10px; border: 1px solid #000;border-radius: 30px;background: rgb(255 187 33);padding: 10px 30px; font-size: 16px;">Confirm</button>
                    </div>
                  </div>
                  
                </div>
        </div>

</template>
<style type="text/css">
    .form-check-inline input[type=radio]{
        position: relative;
        top: 7px;;
    }
</style>

<script>
var moment = require('moment');
export default{

   data(){

      return{
        preordertime:null,
        useraddress:[],
        moment: moment,
        timesting:null,
        new_address:'',
        apartment:'',
        phone:'',
        deleveryhour:0,
        addressvalue:[
         
        ],
      }
    },
   mounted(){
    
     axios.get('/getaddress').then(response=>{
        

         this.useraddress=response.data.myaddress[0];
         this.apartment=response.data.myaddress[0].apartment;
         this.new_address=this.useraddress.address;
         this.phone=response.data.phone;
         this.deleveryhour=response.data.deleveryhour;

         console.log(this.deleveryhour);


        

     })


     console.log('Deleverycharge'+this.deliverycharge);
     console.log('Total Distance'+this.totaldistance);



     

  },

  methods:{

    deleteaddress(){

      axios.get('/address-delete/'+this.useraddress.id).then(response=>{
        

        alert("deleted");

      })

    },
  	submit(){
  		var delevery =$("#delivery_amount").val();
        this.$store.dispatch('Updatemaintotal',{delveryamount:delevery});
        var address=$("#autocomplete-input").val();
        $("#addressstring").text(address);
        $('#addressModal').modal('hide');

        this.$store.dispatch('Addaddress',{
             address:address});

  
  	},
    order(){
        if(this.timesting==null){
        this.$swal.fire({
          title: 'you missed something.',
          text: 'Pre-Order delivery time is require', 
          icon: 'error',
          confirmButtonText: 'Got it'
         })
        }else{

            var address=$("#autocomplete-input").val();
            let newObj= {"address": address, "apartment": this.apartment}
            this.addressvalue.push(newObj);
            axios.post('storeorder',{

                cart:this.cart,
                total:this.totalprice,
                delevery:this.delveerycharge,
                delivery_time:this.timesting,
                address:this.addressvalue[0],
                phone:this.phone,

            }).then(response=>{


                console.log(response.data);
                this.$swal.fire({
                  title: 'Congratulations',
                  text: ' your order has been successfully completed!', 
                  icon: 'success',
                  confirmButtonText: 'Ok'
                 });
                
                window.location.replace(this.url+'account/order/'+response.data);
 


            })



        }

    }

  },
    computed:{
        cart(){
          return this.$store.state.cart;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        },
        maintotal(){
         return this.$store.state.maintotal;   
        },
        deliverycharge(){
           return this.$store.getters.deliverycharge; 
       },
       totaldistance(){
         return this.$store.getters.totaldistance;
       }

    },
    watch:{
        preordertime(value){
            var day=this.deleveryhour/24;
            this.timesting=moment().add(day, 'days').format("MMM Do YY")+' '+value;
            $(".deliverytime").text(this.timesting);
            

        }
    },





}
</script>