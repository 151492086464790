<template>
	<div class="FAQ_faqQuestionsWrapper__v6MxX MuiBox-root css-0">

	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">PRIVACY NOTICE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Creating Culinary Communities, LLC, SBE Restaurant Group, LLC d/b/a Disruptive Restaurant Group and their affiliates (collectively, “we”, “us” or “our”), is the controller of your personal data under this Privacy Notice and is a global hospitality and entertainment company comprised of a thriving collection of award-winning hotels, casinos, residences, restaurants and nightlife destinations (the “Venues”). As a part of our operations, we gather certain types of information from and about online visitors and users through various websites.
This Privacy Notice explains our practices with respect to personal data we collect and process. It applies to information collected from and about visitors and users (“you”) through various websites operated by us (either directly or through our service providers) their respective web pages, sub-domains, and any other websites (and subdomains thereof) that we operate and that link to this Privacy Notice (collectively, the “Website”). This Privacy Notice also applies to information collected from or about you through email and other electronic submissions and communications between you and us, as well as to information collected at our Venues (collectively, our “Services”).
This Privacy Notice does not apply to information collected by any other company or third-party site, application or content (including advertising) that may link to or be accessible from the Website, nor directly through certain of our service providers or partners, such as OpenTable, who may have their own policies that govern their use of your personal data; to the extent your personal data is transferred to us, depending on the purpose and basis on which it was transferred, it will be subject to this Privacy Notice or to the privacy terms of such service provider or partner. Certain transactions and services offered while using the Services may be processed or provided by service providers and other strategic partners, even though it appears that you may not have left the Website or our Services (e.g., service providers that book reservations, carry out know-your-customer functions, or provide payment processing or hosting services).
Please read this Privacy Notice carefully. It explains how we process and safeguard personal data about you. By visiting or using our Services, and/or by voluntarily providing personal data to us through or through or in connection with the Services, you acknowledge that you have read and understand the practices contained in this Privacy Notice. If you are providing information through the Website for or on behalf of a third party (e.g., customer, client, employee, family member, friend, event guest) you hereby represent that you have the right to do so and that you have provided that party with a copy of this Privacy Notice, and all references to “you” or “your” data or personal data herein refers to the data or information of both you and such individual, as applicable.
This Privacy Notice is subject to any agreements, including the Terms of Use posted on our Website, which govern your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions. We encourage you to review this Privacy Notice frequently, as it may change from time to time. Click here to go directly to the “Changes to This Privacy Notice” section below that describes how changes to this Privacy Notice will be communicated to you and made effective.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">TYPES OF INFORMATION COLLECTED ABOUT YOU AND HOW IT IS COLLECTED<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">1. INFORMATION YOU SHARE WITH US
 2. AUTOMATICALLY-COLLECTED INFORMATION
 3. COOKIES AND OTHER TRACKING TECHNOLOGIES
 4. INFORMATION OBTAINED FROM THIRD PARTIES
HOW WE PROCESS PERSONAL DATA
 1. LEGAL BASES FOR PROCESSING
 2. OPERATIONAL PROCESSING OF PERSONAL DATA
 3. HOW WE SHARE PERSONAL DATA
RETENTION OF PERSONAL DATA
YOUR RIGHTS REGARDING PERSONAL DATA
 1. ACCESSING, MODIFYING, RECTIFYING, AND CORRECTING COLLECTED PERSONAL DATA
 2. YOUR PRIVACY RIGHTS
 3. YOUR CALIFORNIA PRIVACY RIGHTS
 4. YOUR NEVADA PRIVACY RIGHTS
YOUR CHOICES
INTEREST-BASED ADVERTISING
ANALYTICS
PUBLIC FORUMS
CHILDREN 
SECURITY
HOW WE RESPOND TO DO NOT TRACK SIGNALS 
OTHER WEBSITES/LINKS 
INTERNATIONAL USE AND DATA TRANSFERS
CHANGES TO THIS PRIVACY NOTICE 
CONTACT US 
CALIFORNIA PRIVACY NOTICE
</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">A. TYPES OF INFORMATION COLLECTED ABOUT YOU AND HOW IT IS COLLECTED<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We and our service providers collect various types of information from and about you, including:
•	“Personal data,” which is information about an identified or identifiable individual. This includes, for example, your name, email address, telephone numbers, company affiliation, title, physical address, screen names, credit card details (including the three or four digit security code on the back of your credit card), bank account or other payment information, credit information, birth date, driver’s license number, social security number, passport number, frequent flyer or other affinity program numbers, employment information, and any other information by which you may be personally identified. Personal data also may include information that may not be personally identifiable standing alone (for example, your age), but which may become personally identifiable when combined with other personal data (for example, your age combined with your name).
•	“Non-personal data” is information that is about you, but which does not, and cannot be used to, identify you individually and is not linked to any personal data, including: aggregated data, such as information regarding use of our Website (including clickstream data, your movement within and interaction with the Website, including what pages you look at, and the time, date, and duration of your visit to the Website) and traffic patterns relating to the Website. Non-personal data may include personal data that has been anonymized in such a manner that it cannot be used, alone or in connection with other information, to identify you. Non-personal data also may be aggregated with non-personal data about others to create aggregate data (including demographic information about the age ranges of our users, average size of bookings, percentage of repeat customers, or number of users who visit the Website by clicking links contained in advertising materials).
We and our service providers may collect this information through various means, including: (a) directly from you when you provide it to us, for example, via forms that you fill out on the Website, through emails and other communications and information that you submit via the Website, and through your other activities on the Website; (b) automatically as you navigate through and interact with the Website, including via server logs, cookies, pixels, beacons, analytics, and other tracking technologies; and (c) from third parties, including without limitation our service providers, analytics providers, property owners, travel agents, corporate travel managers, online travel agencies, intermediaries, analytics providers, and through your interactions with us on or involving social media websites or accounts.
1. Information You Share With Us
The information we and our service providers collect may include the following types of personal data that you share with us:
Information you provide when you register for the Website, make a purchase, participate in a service or offering made available on the Website, make a reservation, or sign up for our wireless Internet service, including without limitation your contact information (such as your name, address, zip code, email address, and/or phone numbers), your company name, job title and address, your screen name or username, and your payment information (such as your credit card details, bank and bank account routing information, or other information);
•	Your demographic information, such as your age, gender, marital status, number of children, and nationality;
•	Your travel-related information, such as your frequent flyer number, travel rewards program number, airlines, rental car providers, passport information, and travel history;
•	Information relating to your reservations, visit or stay at our Venues, including the Venues you have visited, your arrival and departure dates, special requests made, spa and dining reservations, your guest preferences, vehicles you may bring to the Venues, and your transactions and purchases;
•	Certain health information that you choose to share with us or our service providers (such as allergy information, accessibility requirements, or other health conditions that impact your guest preferences or special requests when visiting one of our Venues);
Information that you choose to provide to us at or through any of the Venues;
•	Information you provide to sign up for the Accor Live Limitless (“ALL”) loyalty program (such as your name, email address, physical address, mobile phone number, gender, zip code, birth date (excluding year), social media handles, and social blogs) and information needed to administer the ALL program.
•	Information that you share in response to surveys and other market research;
•	Information that you include in an email, general comment, or recommendation sent through on our Website, as well as records and copies of that correspondence (including email addresses);
•	If you choose to log into the Website through social sign ins (such as Facebook Connect), personal data collected from your social media account consistent with your settings within the applicable social media service, such as photos, name, gender, contact information, and other information;
•	Resumes and applications for employment at the Venues, including any personal, confidential, and/or sensitive information that you provide therein;
•	Your communications preferences; and
•	Other personal data that you choose to provide to us.
Whether you provide this information is your choice; however, please bear in mind that in many instances certain personal data is necessary for you to participate in a particular activity, for us to provide a certain service or benefit to you, or for you to gain access to certain features, services or content on our Website. If you elect not to provide us with certain personal data, you may not be able to participate in a particular activity, realize a benefit we may offer, or gain access to certain content, functionalities or services.
 2. Automatically-Collected Information
When you visit and as you navigate through and interact with the Website, we, our service providers, and third parties may automatically collect information about your visits to our Website, your equipment, and your browsing action and patterns and your activities on the Website. This information may include, but is not limited to:
•	Details of your visits to our Website and the resources that you access, such as traffic data, location data, logs and other communication data, the third-party website from which your visit originated, clickstream data, the search terms you use on our Website, your movement within and interaction with the Website, including what pages you look at, and the time, date, and duration of your visit to the Website.
•	Information about your computer or devices and Internet connection, such as your IP address, your general geographic location, your computer or device type and its capabilities and features, the operating system or platform you are using, your browser type, and your Internet Service Provider.
•	Information about traffic patterns, number of visits to certain pages, visits from other websites or to third-party websites linked to the Website, and visitors’ use of particular services and interest in services, information or features of the Website.
Automatically-collected information often is processed to create aggregated statistical or demographic data. We may share such aggregated data with third parties as described in the “How We Share Your Information” section below. Automatically-collected information that contains or constitutes personal data, as well as non-personal data that we or our service providers maintain or associate with personal data collected in other ways or received from third parties, is be treated as personal data and used and shared as disclosed in this Privacy Notice.
3. Cookies and Other Tracking Technologies
We, our service providers and other third parties may place “cookies” on our Website to collect certain information. A “cookie” or “browser cookie” is a small text file that can be stored on your computer’s hard drive or other device, if your web browser or device settings permit. Certain features of the Website also may use local stored objects (or Flash Cookies) to collect and store information, including information about your preferences and navigation to, from, and on our Website. Cookies allow us and our service providers, for example, to customize your experience and make your visit to the Website easier (for example, recognizing when you return, remembering your viewing preferences, enabling social media sign in functionality, facilitating social sharing, and, if you are a registered user, remembering your password), measure activity on the Website, track conversions, target ads, and provide analytics. While some cookies are deleted when you close your browser, others remain even after you close your browsing session.
We, our service providers and other third parties also may use pixel tags, web beacons or clear gifs, which are tiny files with a unique identifier, similar in function to cookies, that are placed in the code of a web page or an email. These technologies might be used, for example, to allow us and our service providers to track conversions, to send emails to you in a format you can read, to count users who have visited our Website, seen our ads, opened our emails or clicked on any links in our emails, to inform us what content and advertising is effective, to optimize the Website and its content, to reduce or eliminate messages sent to customers, to enhance Website usability, to provide you with ads and other promotions and content that may be of interest to you based on your online activities, and to collect other information about you and your visit when you use the Website. In addition, when you click on a link in an email, we or our service providers may record the individual response to allow us to customize our offerings to you.
We and our service providers may analyze the information derived from cookies, pixels and other technologies and match or combine that information with data provided by you or another party. We engage various service providers who may use unique identifiers, cookies, pixels, beacons, and other technologies to provide us with analytics about how and in what ways you interact with us and use the Website, and to deliver advertisements and content that may be of interest to you (known as “interest-based advertising” or “online behavioral advertising”). For more information about analytics and our vendors, please see the “Analytics” section below, and for more information about interest-based advertising, please see the “Interest-Based Advertising” section below. To the extent that our service providers are using pixels, cookies, beacons, or other tracking technologies, we do not control the use of those technologies or the resulting information, and we are not responsible for the policies of those companies.
For more information about the cookies and tracking technologies we use on the Website, what they do, and how to manage, opt out, delete, block, or disable them (as applicable), consult our Cookie Notice. Please keep in mind, however, that you may not be able to access the full functionality of our Website if cookies are disabled.
4. Information Obtained from Third Parties
We receive personal data from third parties that book stays or order services on your behalf (such as travel agencies, intermediaries, and travel managers), from properties where you book stays, from third parties that provide services to us or to you on our behalf, as well as from third parties that provide web analytics and usage information to us.
In addition, if you choose to interact with us on social media platforms by posting to our social media accounts or pages, tagging us (or using certain hashtags or other identifiers) in posts, or participating in activities, we may collect certain information from the social media account you use to interact with us depending on the social media platform and your account settings, including the name associated with the account, the account handle, recent activity, the content of any posts in which we are tagged, and other information that may be contained on your social media profile to allow us to respond to the posts and understand and engage with our audience.
For the purposes of this Privacy Notice, “partners” means licensors, franchisees, vendors, service providers, and other third parties with which we have a business relationship.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">B. HOW WE PROCESS PERSONAL DATA<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">1.  Legal Bases for Processing
We process personal data for, or based on, one or more of the following legal bases:
•	Performance of a Contract. We use personal data to enter into and to perform under, an agreement between us, such as when you book or pay for stays, order products or services or request a proposal for a meeting or event.
•	Legitimate Interests. We use personal data for our legitimate interests, including contact information, booking and request history, Service interaction, and Website usage data.
•	Compliance with Legal Obligations and Protection of Individuals. We may use personal data to comply with the law and our legal obligations, as well as to protect you and other individuals from certain harms.
•	Your Consent. We process certain personal data because you have given us your consent to process it in that manner.
2.  Operational Processing of Personal Data 
We may process non-personal data (including aggregated information) for any lawful purpose. In addition, we and our service providers may use personal data collected from or about you as described in this Privacy Policy for the purposes disclosed to you or as otherwise permitted or required by law, including:
•	To provide, track, and process the services, purchases and transactions that you request, including facilitating hotel accommodation, spa, dining, and entertainment reservations;
•	To process enrollments and applications, as well as registration in, the administration of, and the award and redemption of benefits under ALL;
•	To accommodate your preferences, to personalize and enhance your experience and the services provided to you at the Venues, and to improve the Venues and our services;
•	To validate your identity, to verify communications from you, and to respond to your emails, submissions, questions, comments, requests for information, and complaints and to provide customer service;
•	To manage, store and enhance guest data and preferences;
•	To send you updates and information, such as communications about purchases, programs you have joined, and changes to our terms, conditions and policies;
•	To conduct customer satisfaction, market research or quality assurance surveys ;
•	To conduct market research and to develop new Venues and services;
•	To conduct and administer sweepstakes, prize draws and other contests;
•	For marketing purposes, including without limitation interest-based advertising, other targeted promotions, sending you other promotional materials, and notifying you about special promotions, offers, events or opportunities, including for select marketing partners of ours;
•	To send you marketing communications about the products and services offered by our property owners, marketing partners and other third parties, including without limitation offers regarding residential sales opportunities;
•	To determine benefits and rewards;
•	For financial reporting and analysis;
•	To cross-reference, supplement and combine with other information that we or our service providers have acquired about you or may acquire about you through other sources, except where prohibited by applicable law or regulation (if any non-personal data is combined with personal data, the combined information will be treated as personal data pursuant to this Privacy Notice for as long as it remains combined);
•	To monitor, track, and analyze Website usage and trends, to personalize and improve the Website, and to increase the Website’s functionality;
•	To send you confirmations, updates, security alerts, and support and administrative messages and otherwise facilitate your use of, and our administration and operation of, the Website;
•	To protect the security or integrity of our network, systems, Website, the Venues and business;
•	To prevent fraud and other prohibited or illegal activities, to investigate and resolve disputes and problems, and to otherwise comply with applicable laws and regulations;
•	To enforce our Terms of Use and other contractual obligations;
•	For any other purpose that is disclosed to you at the point of collection of the personal data;
•	For any purpose for which you provide your prior consent; and
•	In other ways naturally associated with the circumstances in which you provided the personal data, and for any other lawful purpose.

In addition, we use IP addresses for purposes of system administration, for Website optimization, Website security assessments, to report non-personal aggregate information to others, to track the use of our Website, and to get a sense of where our Website visitors are located. We also we use your IP address to personalize content on the Website and to identify you and to share that information as described in the “How We Share Your Information” section below.
3. How We Share Your Information
We may share non-personal data with any third parties for any lawful purpose. Unless otherwise prohibited by law, we disclose personal data collected from or about you as described in this Privacy Notice to the following types of third parties and for the following purposes:
•	Affiliated Companies: We may share your information with our parents, subsidiaries, affiliated companies, and other controlled affiliates (including casinos, hotels, restaurants and other business ventures that are licensed, owned, operated, and/or managed by us or our parents, subsidiaries, affiliated companies and other companies under common control), who may use personal data for any of the purposes disclosed in or consistent with the terms of this Privacy Notice (including without limitation those identified in the “How We Process Your Information” section above);
•	Property Partners: We may share your information with owners of Venues that we manage but do not own, owners and operators of licensed Venues, and owners and operators of certain residential properties associated with or located in proximity to various Venues (as well as the respective designees of any of the foregoing), who may use personal data to provide you with services at or relating to the Venues, to provide you marketing information relating to real estate and other opportunities, and for any of the other purposes disclosed in or consistent with the terms of this Privacy Notice (including without limitation those identified in the “How We Process Your Information” section above).
•	Marketing Partners: We do not share your personal data with our co-promotional partners and other business partners with whom we have marketing or other relationships for joint marketing purposes, but in certain circumstances do allow you to opt-in or otherwise acknowledge or consent the direct provision of your personal data to such parties when you provide it to us.
•	Service Providers: We may share your information with our service providers who assist us by performing core services (such as reservations, hosting, billing, fulfillment, data storage, security, processing credit card payments, customer service, credit reporting, property management, customer relationship management, “know-your-customer,” accounting, auditing, processing insurance claims, investigating accident reports, administering sweepstakes, surveys, email and mailing services, and delivering packages) related to the operation of the Venues, the provision of services at the Venues, the operation of the Website, the processing and fulfillment of reservations or product orders, and/or by making certain Website functionality available to our users.
•	Business Transactions: In the event of a sale, merger, consolidation, change in control, transfer of substantial assets, bankruptcy, reorganization, or liquidation, we may transfer, sell, or assign to third parties information concerning your relationship with us, including, without limitation, personal data collected from or about you provide, and other information concerning your relationship with us. Any successor may use your information for the same purposes set forth in this Privacy Notice.
•	Legal and Compliance: We reserve the right to your personal data to identify you and, where permitted or required by law or applicable regulation, we will provide any information about or relating to you, including any personal data about you, to third parties if we reasonably believe that such action is necessary to: (a) comply with a court order, subpoena or other legal or regulatory requirements; (b) otherwise fulfill a government request for information; (c) protect or defend our legal rights or property, our Venues, our Website, or other guests and online users; (d) respond to claims that any posting or other content of our Website violates the rights of third parties, including without limitation providing information necessary to satisfy the notice and counter-notice procedures pursuant to the Digital Millennium Copyright Act; (e) in an emergency, to protect the health and safety of our Website’s users or the general public; or (f) enforce compliance with our Terms of Use or other contracts.
•	Purpose Fulfillment: We disclose personal data to fulfill the purpose for which you provide it.
•	Consent: We disclose personal data with your consent and/or when you request that we do so.
We will not disclose the amount of your specific gaming winnings or gaming losses to any third party unless required by applicable law or regulation. For more information about how to opt out of receiving future promotional information from us or our affiliated companies, go to the “Your Choices and Access” section below.
</p>
      </div>
    </div>
	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">C. RETENTION OF PERSONAL DATA <div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We retain personal data for as long is necessary or appropriate to fulfil the purpose for which it was collected, as well to the extent necessary or appropriate to carry out the processing activities described above, including but not limited to compliance with applicable laws, regulations, rules and requests of relevant law enforcement and/or other governmental agencies, and to the extent we reasonably deem necessary to protect our and our partners’ rights, property, or safety, and the rights, property, and safety of our users and other third parties. We consider multiple factors, including legal requirements, the scope and nature of the personal data, and potential risk of harm to data subjects from a data breach, in determining the appropriate retention period. If you have a specific question regarding retention of your personal data, you may contact us as described in the “Contact Us” section below.</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">D. YOUR RIGHTS REGARDING PERSONAL DATA<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">You have a variety of legal rights regarding the collection and processing of personal data. You may exercise these rights, to the extent they apply to you, by contacting us as described in the “Contact Us” section below, or by following instructions provided in this Privacy Notice or in communications sent to you. Please be prepared to provide reasonable information to identify yourself and authenticate your requests.
Note, however, that we may request certain reasonable additional information (that may include personal data) to help us authenticate the request and/or to clarify or understand the scope of such requests.
These rights vary depending on the particular laws of the jurisdiction applicable to you, but may include:
•	The right to know whether, and for what purposes, we process personal data about you;
•	The right to be informed about the personal data we collect and/or process about you;
•	The right to learn the source of personal data about you we process, where we obtain that personal data from a source other than you.
•	The right to access, modify, and correct personal data about you (as set forth in more detail below under“Accessing, Modifying, Rectifying, and Correcting Collected Personal Data”)
•	The right to know with whom we have shared personal data about you, for what purposes, and what personal data has been shared (including whether personal data was disclosed to third parties for their own direct marketing purposes);
•	Where processing of personal data about you is based on consent, the right to withdraw your consent to such processing; and
•	The right to lodge a complaint with a supervisory authority located in the jurisdiction of your habitual residence, place of work, or where an alleged violation of law occurred.
See “Your Rights”, “Your California Privacy Rights” , and “Your Nevada Privacy Rights” for more information about certain legal rights.
1.  Accessing, Modifying, Rectifying, and Correcting Collected Personal Data
We strive to maintain the accuracy of any personal data collected from you, and will use commercially reasonable efforts to respond promptly to update our database when you tell us the information in our database is not accurate. However, we must rely upon you to ensure that the information you provide to us is complete, accurate, and up-to-date, and to inform us of any changes. Please review all of your information carefully before submitting it to us, and notify us as soon as possible of any updates or corrections as described in the “Contact Us” section below. 
In accordance with applicable law, you may obtain from us certain personal data in our records. You can contact us as described in the “Contact Us” section below to access, review, update, or correct certain personal data. If you have registered an account on the Website, you can also login to your account to change or update certain information and preferences to keep such data accurate, complete and up-to-date. Please note, however, that we reserve the right to deny access as permitted or required by applicable law.
2. Your Privacy Rights
In addition to the above-listed rights, certain laws provide individuals with enhanced rights in respect of their personal data. These rights may include, depending on the facts and circumstances surrounding, and in certain cases the legal basis for, the processing of personal data, following:
•	The right to be informed of whether we hold personal data about you.
•	The right to object to decisions based on profiling or automated decision-making that produce legal or similarly significant effects on you;
•	The right to request restriction of processing of personal data or object to processing of personal data carried out pursuant to (a) a legitimate interest (including, but not limited to, processing for direct marketing purposes) or (b) performance of a task in the public interest;
•	The right to challenge the accuracy and completeness of your personal data and have it amended as appropriate;
•	The right to be provided with information about our policies and practices with respect to the management of personal data, including: (a) the name or title, and address, of the person who is accountable for our privacy policies and practices; (b) the means of gaining access to personal data; (c) a description of the type of personal data held by us, including a general account of its use; (d) a copy of any brochures or other information that explain our policies, standards, or codes; and (e) what personal data is made available to related organizations;
•	The right to data portability, which means that you can request that we provide certain personal data we hold about you in a machine-readable format; and
•	The right to erasure and/or the right to be forgotten, which means that you can request deletion or removal of certain personal data we process about you.
Where required by applicable law, we will respond to a valid request relating to your rights within one month of receipt, or within three months where a request is complex or challenging. Note that we may need to request additional information from you to validate your request.
3. Your California Privacy Rights
California residents, please review the California Privacy Notice for information about certain legal rights.
4. Your Nevada Privacy Rights 
Nevada residents have the right to request that we do not sell your personal data at any time in the future. If you are a Nevada resident and wish to exercise this right, please email privacy@sbe.com with your request that we do not sell your personal data.
</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">E. YOUR CHOICES <div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">In certain instances, we may provide you with the option to set your preferences for receiving email communications from us; that is, you can agree to receive some communications but not others. At any time, you also may opt-out of receiving future commercial emails from us by following the “Unsubscribe” instructions contained in any commercial emails that you receive from us. You also may contact us as described in the “Contact Us” section if you want to opt-out from receiving future commercial correspondence, including marketing emails, from us. We will respond to your request and, if applicable and appropriate, make the requested change in our active databases within a reasonable timeframe in compliance with applicable law. Please remember that we may not be able to fulfill certain requests while allowing you access to certain benefits and features. Even if you unsubscribe from our marketing communications, you may continue to receive certain communications from us, such as transactional, customer service or other relationship messages, messages about your account and profile, and emails in response to communications or requests for information that we receive from you.
If you want to limit or prevent our ability to collect location information from you, you can deny or remove the permission for the Website to access location information or deactivate location services on your device. Please refer to your device manufacturer or operating system instructions for instructions on how to do this.
Finally, consult our Cookie Notice for more information about how to control and/or opt out of certain web tracking technologies.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">F. INTEREST-BASED ADVERTISING<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We may allow third parties to collect information about your online activities on our Website through unique identifiers, cookies, pixels, beacons and other technologies (as described above). These third parties include advertising networks that may use unique identifiers, cookies, pixels, beacons, and other technologies to track and collect information about your interests when you visit our Website and other websites or view or interact with one of the advertisements they place on various websites or mobile platforms. The information gathered by these third parties is used to make predictions about your characteristics, interests or preferences and to display advertisements on our Website and across the Internet tailored to your apparent interests.
We use Google’s Remarketing service with Google AdWords to show you our ads as you browse other websites around the Internet. The Remarketing service delivers our ads through the Google AdWords display network, which includes both Google.com pages and the sites of trusted publishers. Third-party vendors, including Google, use cookies (such as the DoubleClick cookie) to serve ads based on your past visits to the Website.
Consult our Cookie Notice for more information about interest-based advertising, as well as how to control and/or opt out of certain web tracking technologies, including cookies and certain interest-based advertising providers.
</p>
      </div>
    </div>
	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">G. ANALYTICS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We engage service providers to provide analytics data for the purposes of understanding and optimizing web usage, for business and market research purposes, measuring conversion tracking, and measuring the effectiveness of our content and online advertising campaigns. These service providers may use cookies alone or in conjunction with unique identifiers, pixels, beacons and other tracking technologies to collect information about you when you use the Website.
Consult our Cookie Notice for more information about analytics, as well as how to control and/or opt out of certain web tracking technologies, including cookies and certain analytics providers.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">H. PUBLIC FORUMS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We may offer chat rooms, blogs, message boards, bulletin boards, or similar public forums where you and other users of our Website can communicate. Unless otherwise required by applicable law or regulation, the protections described in this Privacy Notice do not apply to information (including personal data) that you post to these public forums.
We may use personally identifiable and non-personal data about you to identify you through your postings in a public forum. Any information you share in a public forum is public information and may be seen or collected by anyone, including third parties that do not adhere to our Privacy Notice. We are not responsible for events arising from the distribution of any information you choose to publicly post or share through our Website.
</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">I. CHILDREN<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">The Website is a general audience site, and the features, programs, promotions and other aspects of our Website requiring the submission of personal data are not intended for anyone under 18 years of age. If you are under 18, you may not access, use or register on the Website. We do not knowingly collect personal data from individuals under 18. If you are a parent or guardian of an individual under 18 and believe he or she has disclosed personal data to us without your permission, please contact us at privacy@sbe.com or otherwise as provided in the “Contact Us” section below. A parent or guardian of a child under the age of 18 may request deletion of such child’s personal data as well as prohibit the use thereof.</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">J. SECURITY<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We take reasonable steps to protect against the loss, misuse and alteration and protect the confidentiality, integrity, availability, and resilience of the information under our control, including Secure Sockets Layer (SSL) technology to encrypt the transmission of non-public personally identifiable financial and transaction information over the Internet, provided you are using an SSL-enabled device. We also utilize other reasonable security measures such as firewalls, security patches, and anti-virus programs, to protect non-public personal data. We also take steps to ensure personal data is backed up and remains available in the event of a security incident. We periodically test, assess, and evaluate the effectiveness of our safeguards and security controls.
Despite these efforts, please be advised that no security system or means of transmitting data over the Internet can be guaranteed to be entirely secure (including without limitation with respect to computer viruses, malicious software and hacker attacks), and we cannot and do not guarantee or warrant the security of any information you disclose or transmit to us via the Internet, and are not responsible for the theft, destruction, or inadvertent disclosure of your personal data. For your own privacy protection, we recommend that you do not include any non-public personal or sensitive personal data such as passwords, social security numbers, credit card details, or bank account information, in any emails that you send to us. We will not contact you by email or text message to ask for such information.
</p>
      </div>
    </div>
	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">K.HOW WE RESPOND TO DO NOT TRACK SIGNALS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Some web browsers may give you the ability to enable a “do not track” setting that is an HTTP header field indicating your preferences regarding tracking or cross-site user tracking. This is different from disabling certain forms of tracking by declining cookies in your browser settings, as browsers with the “do not track” setting enabled still have the ability to accept cookies.
The Website does not recognize web browser “do not track” signals at this time. We may work with third parties that use tracking technologies on the Website, including in order to serve tailored advertisements on our behalf and on behalf of other advertisers across the Internet. These companies may collect information about your activity on the Website and other websites over time, as well as your interaction with our advertising and other communications, and use this information to determine which ads you see on third-party websites and applications. For more information about this practice and to understand your options, please visit http://www.networkadvertising.org/or http://www.aboutads.info.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">L. OTHER WEBSITES/LINKS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">As a convenience, our Website may reference or provide links to third-party websites and/or services that we do not control or maintain. When you access these third-party websites or services, you leave our Website, and we are not responsible for, and do not control, the content, security, or privacy practices employed by any third-party websites and services. You access such third-party websites and services at your own risk. Be aware that those third-party websites collect information and operate according to their own privacy practices. We are not responsible for the privacy practices employed by any third-party website. We encourage you to note when you leave the Website and to read the privacy statements of all third-party websites to understand how they collect, use and disclose personal data before submitting any personal data to those websites.</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">M. INTERNATIONAL USE AND DATA TRANSFERS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Your personal data will be stored and processed in the United States and the locations of the Venue(s) with which you interact. If you are using the Website or other Services from outside the United States, by your use of the Website or other Services you acknowledge that we will transfer your data to, and store your personal data in, the United States, which may have different data protection rules than in your country, and personal data may become accessible as permitted by law in the United States, including to law enforcement and/or national security authorities in the United States. For transfers of data out of the European Economic Area, we use standard contractual clauses, and, as appropriate, other applicable mechanisms.</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">N. CHANGES TO THIS PRIVACY NOTICE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">This Privacy Notice replaces all previous disclosures we may have provided to you about our information practices with respect to the Website. We may change or update this Privacy Notice in the future. You can identify the date that this Privacy Notice was last updated by looking at the date at the top of this Privacy Notice. Any revisions to this Privacy Notice will take effect upon posting to the Website. We will notify you of any material changes to this Privacy Notice either by sending an email to the email address that you provided to us and/or by placing a notice on the homepage of the Website. We encourage you to regularly review the current version of this Privacy Notice on the Website. Your use or continued use of the Website following posted changes constitutes your acknowledgement of the revised Privacy Notice then in effect.</p>
      </div>
    </div>

    	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">O. CONTACT US<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">If you have any questions, comments or concerns about this Privacy Notice, or if you would like to exercise the choices discussed above, please contact us (a) by email at privacy@sbe.com, or (b) by postal mail in the U.S. at S Creating Culinary Communities, LLC, SBE Restaurant Group, LLC d/b/a Disruptive Restaurant Group, 9247 Alden Drive. Beverly Hills, CA 90210. Attn: Privacy Office and in the UK at Saint Martin’s Lane London, 45 St. Martin's Lane, London, WC2N 4HX, Attn: Privacy Office.
If you are located in the European Economic Area, and you wish to raise a concern regarding our use of your personal data, you have the right to do so with our lead supervisory authority, the U.K. Information Commissioner’s Office (the “ICO”) at www.ico.org.uk , or your local supervisory authority. We would, however, appreciate the opportunity to deal with your concerns before you approach the ICO, so please contact us in the first instance.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">CALIFORNIA PRIVACY NOTICE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">This California Privacy Notice (“California Notice”) supplements the Privacy Notice and applies solely to visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopted this California Notice to comply with California privacy laws, including the California Consumer Privacy Act (“CCPA”). Any terms defined in the CCPA and not defined here have the same meaning when used in this California Notice.
A. Collection and Use of Personal Data
We collect information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household (“personal data”). Automatically-collected information often is processed to create aggregated statistical or demographic data. Automatically-collected information that contains or constitutes personal data, as well as non-personal data that we or our service providers maintain or associate with personal data collected in other ways or received from third parties, is be treated as personal data and used and shared as disclosed in this California Notice.
1. Use of personal data
We collect and process your personal data for our business purposes, which include the following:
i. To provide, track, and process the services, purchases and transactions that you request, including facilitating hotel accommodation, spa, dining, and entertainment reservations;
ii. To process enrollments and applications, as well as registration in, the administration of, and the award and redemption of benefits under our third party loyalty program provider, Accor Live Limitless (“ALL”), according to your direction or use of our Website and/or Venues to intentionally interact with ALL;
iii. To accommodate your preferences, to personalize and enhance your experience and the services provided to you at the Venues, and to improve the Venues and our services;
iv. To validate your identity, to verify communications from you, and to respond to your emails, submissions, questions, comments, requests for information, and complaints and to provide customer service;
v. To manage, store and enhance guest data and preferences;
vi. To send you updates and information, such as communications about purchases, programs you have joined, and changes to our terms, conditions and policies;
vii. To conduct customer satisfaction, market research or quality assurance surveys;
viii. To conduct market research and to develop new Venues and services;
ix. To conduct and administer sweepstakes, prize draws and other contests;
x. For marketing purposes, including without limitation interest-based advertising, other targeted promotions, sending you other promotional materials, and notifying you about special promotions, offers, events or opportunities, including for select marketing partners of ours;
xi. To send you marketing communications about the products and services offered by our property owners, marketing partners and other third parties, including without limitation offers regarding residential sales opportunities;
xii. To determine benefits and rewards;
xiii. For financial reporting and analysis;
xiv. To cross-reference, supplement and combine with other information that we or our service providers have acquired about you or may acquire about you through other sources, except where prohibited by applicable law or regulation (if any non-personal data is combined with personal data, the combined information will be treated as personal data pursuant to this California Notice for as long as it remains combined);
xv. To monitor, track, and analyze Website usage and trends, to personalize and improve the Website, and to increase the Website’s functionality;
xvi. To send you confirmations, updates, security alerts, and support and administrative messages and otherwise facilitate your use of, and our administration and operation of, the Website;
xvii. To protect the security or integrity of our network, systems, Website, the Venues and business;
xviii. To prevent fraud and other prohibited or illegal activities, to investigate and resolve disputes and problems, and to otherwise comply with applicable laws and regulations;
xix. To enforce our Terms of Use and other contractual obligations;
xx. For any other purpose that is disclosed to you at the point of collection of the personal data;
xxi. For any purpose for which you provide your prior consent; and
xxii. In other ways naturally associated with the circumstances in which you provided the personal data, and for any other lawful purpose.
We will not use the personal data we collected for material different, unrelated, or incompatible purposes without providing you with notice and obtaining your consent.
2. Sharing of personal data
We share personal data with the following categories of third parties:
i. Affiliated Companies: We may share your information with our parents, subsidiaries, affiliated companies, and other controlled affiliates (including casinos, hotels, restaurants and other business ventures that are licensed, owned, operated, and/or managed by us or our parents, subsidiaries, affiliated companies and other companies under common control);
ii. Property Partners: We may share your information with owners of Venues that we manage but do not own, owners and operators of licensed Venues, and owners and operators of certain residential properties associated with or located in proximity to various Venues (as well as the respective designees of any of the foregoing).
iii. Accor Live Limitless: We may share your information with our third party loyalty program provider, ALL, according to your direction or use of our Website and/or Venues to intentionally interact with ALL.
iv. Service Providers: We may share your information with our service providers who assist us by performing core services (such as reservations, hosting, billing, fulfillment, data storage, security, processing credit card payments, customer service, credit reporting, property management, customer relationship management, “know-your-customer,” accounting, auditing, processing insurance claims, investigating accident reports, administering sweepstakes, surveys, email and mailing services, and delivering packages) related to the operation of the Venues, the provision of services at the Venues, the operation of the Website, the processing and fulfillment of reservations or product orders, and/or by making certain Website functionality available to our users.
v. Business Transactions: In the event of a sale, merger, consolidation, change in control, transfer of substantial assets, bankruptcy, reorganization, or liquidation, we may transfer, sell, or assign to third parties information concerning your relationship with us, including, without limitation, personal data collected from or about you provide, and other information concerning your relationship with us.
vi. Legal and Compliance: We reserve the right to your personal data to identify you and, where permitted or required by law or applicable regulation, we will provide any information about or relating to you, including any personal data about you, to third parties if we reasonably believe that such action is necessary to: (a) comply with a court order, subpoena or other legal or regulatory requirements; (b) otherwise fulfill a government request for information; (c) protect or defend our legal rights or property, our Venues, our Website, or other guests and online users; (d) respond to claims that any posting or other content of our Website violates the rights of third parties, including without limitation providing information necessary to satisfy the notice and counter-notice procedures pursuant to the Digital Millennium Copyright Act; (e) in an emergency, to protect the health and safety of our Website’s users or the general public; or (f) enforce compliance with our Terms of Use or other contracts.
3. Processing of personal data
Within the last twelve (12) months, we have collected, used, and shared personal data as follows:
	Category of Personal Data Collected	Examples	Collected
(Yes/No)	Sources of Collected Personal Data	Purposes for Using Personal Data 	Categories of Third Parties with whom Personal Data is Shared 
	Identifiers	Real name, alias, postal address, unique personal identifier, online identifier, internet protocol address, email address, account name, driver’s license number, passport number, or other similar identifiers.	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i-xxii	Affiliated Companies; Property Partners; ALL; Service Providers; Business Transactions; Legal and Compliance
	Personal information categories listed in the California Customer Records statute (Cal. Civ. Code 1798.80(e))	Name, signature, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information.	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i-xiv, xviii-xxii	Affiliated Companies; Property Partners; ALL; Service Providers; Business Transactions; Legal and Compliance
	Characteristics of protected classifications under California or federal law	Age, race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, or sex (including gender, gender identity, gender expression).	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i-v, vii-x, xiv, xviii-xxii	Affiliated Companies; Property Partners; ALL; Service Providers; Business Transactions; Legal and Compliance
	Commercial information	Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i, v, vii-viii, x, xiii-xiv, xx-xxii	Affiliated Companies; Property Partners; ALL; Service Providers; Business Transactions; Legal and Compliance
	Biometric information		No			
	Internet or other electronic network activity information	Browsing history, search history, and information regarding a consumer’s interaction with an internet website, application, or advertisement.	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i, iii, v, viii, x, xiv, xv-xxii	Service Providers; Business Transactions; Legal and Compliance
	Geolocation data	Physical location of consumer/ device.	Yes	Directly from you; automatically or indirectly from you	iii, v, viii, x-xi, xiv-xv, xvii-xxii	Service Providers; Business Transactions; Legal and Compliance
	Sensory data	Audio, electronic, visual, thermal, olfactory, or similar information.	Yes	Directly from you; automatically or indirectly from you	xvii-xxii	Business Transactions; Legal and Compliance
	Professional or employment-related information.		No			
	Education information (as defined in 20 U.S.C. section 1232g, 43 C.F.R. Part 99)		No			
	Inferences drawn from any of the other personal data	Profile about a consumer reflecting preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.	Yes	Directly from you; automatically or indirectly from you; service providers and third parties	i, iii, v, xx-xxii	Service Providers; Business Transactions; Legal and Compliance
B. Your California Privacy Rights
California Civil Code Section 1798.83 (“Shine the Light” law) permits residents of the State of California to request from certain businesses with whom the California resident has an established business relationship a list of all third parties to which the business, during the immediately preceding calendar year, has disclosed certain personal data for direct marketing purposes, and the names and addresses of those third parties.
We are only required to respond to a customer request once during any calendar year per person. To make such a request you should send an email to privacy@sbe.com with the subject heading “California Privacy Rights.” In your request, please attest to the fact that you are a California resident and provide a current California address for our response. Requests are free of charge.
Please be aware that not all information sharing is covered by the California privacy rights requirements and only information on covered sharing will be included in our response. We reserve our right not to respond to requests submitted to addresses other than the addresses specified in this paragraph.
The CCPA provides California residents with the following rights:
1. Right to Know: You have the right to request that we disclose certain information to you about the personal data we collected, used, disclosed, and sold about you in the past 12 months. This includes a request to know any or all of the following:
•	The categories of personal data collected about you;
•	The categories of sources from which we collected your personal data, including where we obtain that personal data from a source other than you;
•	The categories of personal data that we have sold or disclosed about you for a business purpose;
•	The categories of third parties to whom your personal data was disclosed for a business purpose;
•	Our business or commercial purpose for collecting or selling your personal data; and
•	The specific pieces of personal data we have collected about you.
2. Data Portability: You have the right to request a copy of personal data we hold about you.
3. Right to Deletion: You have the right to request that we delete the personal data we collected from you and maintained, subject to certain exceptions. Please note that if you request deletion of your personal data, we may deny your request or may retain certain elements of your personal data if it is necessary for us or our service providers to:
•	Complete the transaction for which the personal data was collected, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between our business and you.
•	Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity.
•	Debug to identify and repair errors that impair existing intended functionality.
•	Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law.
•	Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12 of Part 2 of the Penal Code.
•	Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the deletion of the information is likely to render impossible or seriously impair the achievement of such research, if you have provided informed consent.
•	To enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us.
•	Comply with a legal obligation.
•	Otherwise use the personal data, internally, in a lawful manner that is compatible with the context in which you provided the information.
4. Right to Opt-In to Financial Incentives: You have the right to opt-in to ALL, our third party loyalty program provider. You also have the right to opt-out at any time. Please see the “Financial Incentives” section below for more information about ALL.
5. Right to Opt-Out/In: You have the right to opt-out of the sale of your personal data You also have the right to opt-in to the sale of personal data. Please see our “Notice of Right to Opt-Out” by clicking on this “Do Not Sell My Personal Information” link for more information about your opt-out/in rights.
6. Right to Non-Discrimination: You have the right not to receive discriminatory treatment by us for the exercise of your CCPA privacy rights. Unless permitted by the CCPA, we will not:
•	Deny you goods or services.
•	Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
•	Provide you a different level or quality of goods or services.
•	Suggest that you my receive a different price or rate for goods or services or a different level or quality of goods or services.
Please bear in mind that in many instances, certain personal data is necessary for you to participate in a particular activity, for us to provide a certain service or benefit to you, or for you to gain access to certain features, services or content on our Website. If you elect not to provide us with certain personal data, you may not be able to participate in a particular activity, realize a benefit we may offer, or gain access to certain content, functionalities or services. In addition, as described in the section captioned “Your Choices” in the Privacy Notice, it is possible to change your browser settings to block the automatic collection of certain information.]
</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">C. Submitting a Verified Consumer Request<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">To exercise your Right to Know, Data Portability, and Right to Delete, you must provide us with sufficient information to allow us to verify your identity, and describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. Once we receive the information you provide to us, we will review it and determine if more information is necessary to verify your identity as required by law, and we may request additional information in order to do so.
To exercise your California privacy rights described above, please submit a verifiable request to us by:
•	Emailing us at privacy@sbe.com
Only you, or a person authorized by you to act on your behalf, may make a verifiable consumer request related to your personal data.
You may only make a verifiable consumer request for Right to Know or Data Portability twice within a 12-month period. The verifiable consumer request must:
•	Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal data or an authorized representative. We will need to verify your identity with your name and email address.
•	Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
We may deny your request if we are unable to verify your identity or have reason to believe that the request is fraudulent.
1. Consumer Request by an Authorized Agent
If any authorized agent submits a consumer request on your behalf, in order to confirm that person or entity’s authority to act on your behalf and verify the authorized agent’s identity, we require an email be sent to privacy@sbe.com, along with all of the below items:
•	To verify your authorization to request on behalf of a California resident, provide one or more of the following: (1) California Secretary of State authorization, (2) written permission from the California resident, or (3) power of attorney.
•	Sufficient information to verify the authorized agent’s identity, depending on the nature of the request.
•	To verify the identity of the California resident for whom the request is being made, provide the resident’s name, email address, and if requesting a copy of the resident’s personal data, ALL loyalty number.
We cannot respond to your request or provide you with personal data if we cannot verify your identity or authority to make the request and confirm the personal data relates to you. Making a verifiable consumer request does not require you to create an ALL loyalty account with us. However, if you have an account, we will require you to provide your loyalty number when you submit a request. We will only use personal data provided in a verifiable consumer request to verify the requester’s identity or authority to make the request.
We will acknowledge receipt of the request within ten (10) days of its receipt. We will respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. [If you have an account with us, we will deliver our written response to your account. If you do not have an account, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the receipt of the verifiable consumer request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For Data Portability requests, we will provide the responsive information in a portable and, to the extent technically feasible, in a readily useable format that allows you to transmit the information to another entity without hindrance.
We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">D. Sale of Personal Data<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We never monetize your personal data; however, the CCPA has a broad definition of “sale” that includes transactions other than what would be traditionally considered sales. In the past twelve (12) months, we have “sold” (as defined under the CCPA) the following categories of personal data set forth in the table under “Personal Data We Collect” : Internet or other electronic network activity information. We have “sold” your personal data to third parties who provide cross-platform advertising and related tracking for our website.
We do not and will not knowingly sell the personal data of minors under the age of 16 without affirmative authorization.
Do Not Track
We use analytics systems and providers and participate in ad networks that process personal data about your online activities over time and across third-party websites or online services, and these systems and providers may provide some of this information to us. We do not currently process or comply with any web browser’s “do not track” signal or similar mechanisms.
Note, however, that you may find information about how to opt out of Google Analytics, DoubleClick online behavioral advertising, and/or block or reject certain tracking technologies in our Cookie Notice.
</p>
      </div>
    </div>

    	  <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">E. Financial Incentive Program(s)<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">For more information about our ALL loyalty program and how to sign up, please visit https://all.accor.com/canada/index.en.shtml You can opt-out of ALL at any time by opting out within your ALL Account settings.</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">F. Modifications and Updates to this California Notice<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">This California Notice replaces all previous disclosures we may have provided to you about our information practices with respect to the Website. We may change or update this California Notice in the future. You can identify the date that this California Notice was last updated by looking at the date at the top of this California Notice. Any revisions to this California Notice will take effect upon posting to the Website. We will notify you of any material changes to this California Notice either by sending an email to the email address that you provided to us and/or by placing a notice on the homepage of the Website. We encourage you to regularly review the current version of this California Notice on the Website. Your use or continued use of the Website following posted changes constitutes your acknowledgement of the revised California Notice then in effect.</p>
      </div>
    </div>

    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">G. Additional Information and Assistance <div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">If you have any questions, comments or concerns about this California Notice, or if you would like to exercise the choices discussed above, please contact us (a) by email at privacy@sbe.com, or (b) by postal mail in the U.S. at Creating Culinary Communities, LLC, SBE Restaurant Group, LLC d/b/a Disruptive Restaurant Group, 9247 Alden Drive. Beverly Hills, CA 90210, Attn: Privacy Office.</p>
      </div>
    </div>

</div>
</template>
<script>
import $ from 'jquery';
export default{
  mounted(){
    $('.ExpandBlock_expandBtnSquare__mcmRo').click(function(){
        if($(this).hasClass("ExpandBlock_expandOnClickRotate__V0ofh")==true){
            $(this).removeClass('ExpandBlock_expandOnClickRotate__V0ofh');
               if($(this).parent().parent().parent(".ExpandBlock_expandBlockWrapper__p6f5Q > .ExpandBlock_expandBlockContentWrapper__fx3Rk")){
                   $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').removeClass('ExpandBlock_expanded__o57uc');
               }
       }else{
       	 $(this).addClass('ExpandBlock_expandOnClickRotate__V0ofh');
       	 $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').addClass('ExpandBlock_expanded__o57uc');
       }
    });
  }

}
</script>