<template>
<div class="Checkout_checkout__4ebMc Checkout_variantB__wUyh2">
        <div class="Checkout_checkoutHeader__2XZIL" @click="home()">
          <div class="Checkout_checkoutHeaderLeft__D6aTG">
            <div class="Checkout_checkoutHeaderBackBttn__dqSZ4">
              <div class="BackButton_backButton__bXdaN" style="width: inherit; height: inherit;">
                <div class="BackButton_backButtonIcon__hyaqP">
                  <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.995 6.00019H3.40501L6.70501 2.71019C6.89332 2.52188 6.9991 2.26649 6.9991 2.00019C6.9991 1.73388 6.89332 1.47849 6.70501 1.29019C6.51671 1.10188 6.26132 0.996094 5.99501 0.996094C5.72871 0.996094 5.47332 1.10188 5.28501 1.29019L0.285014 6.29019C0.193973 6.38529 0.122608 6.49743 0.0750135 6.62019C-0.0250045 6.86365 -0.0250045 7.13672 0.0750135 7.38019C0.122608 7.50294 0.193973 7.61508 0.285014 7.71019L5.28501 12.7102C5.37798 12.8039 5.48858 12.8783 5.61044 12.9291C5.7323 12.9798 5.863 13.006 5.99501 13.006C6.12703 13.006 6.25773 12.9798 6.37959 12.9291C6.50145 12.8783 6.61205 12.8039 6.70501 12.7102C6.79874 12.6172 6.87314 12.5066 6.9239 12.3848C6.97467 12.2629 7.00081 12.1322 7.00081 12.0002C7.00081 11.8682 6.97467 11.7375 6.9239 11.6156C6.87314 11.4937 6.79874 11.3831 6.70501 11.2902L3.40501 8.00019H14.995C15.2602 8.00019 15.5146 7.89483 15.7021 7.70729C15.8897 7.51976 15.995 7.2654 15.995 7.00019C15.995 6.73497 15.8897 6.48062 15.7021 6.29308C15.5146 6.10554 15.2602 6.00019 14.995 6.00019Z" fill="currentColor"></path>
                  </svg>
                </div>
              </div>
            </div>
            <h1> Checkout </h1>
          </div>
          <div class="images_image__HR6kE images_imgResponsive__vrazc Checkout_checkoutHeaderImg__xJBxD">
            <span style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <img alt="Checkout Logo" sizes="100vw"  src="/images/goByCitizensSqWhiteTrans.png" decoding="async" data-nimg="fill" class="images_nextjsImageWithLoader__MUAQt images_nextjsContain__i7VXm" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;">
            </span>
          </div>
        </div>
        <div class="Checkout_checkoutMain__Yo96a">
          <div class="Checkout_checkoutInner__PRtQ3">
            <div class="CheckoutProgressPanel_checkoutProgressLarge__XOGu8">
              <section class="CardContainer_checkoutBox__mHMXn CardContainer_withoutFooter__lkgb0">
                <div class="CardContainer_checkoutBoxHeader__JHsWE">
                  <div>
                    <h2 class="CardContainer_checkoutBoxTitle__eisK2">Checkout Progress</h2>
                  </div>
                </div>
                <div class="CheckoutProgressPanel_checkoutProgressWrapper__ppaDU">
                  <div class="CheckoutProgressPanel_checkoutProgress__W0LWb">
                    <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                      <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu">1</div>
                      <div class="CardContainer_additionalText__INNXk">Personal</div>
                    </div>
                    <div class=" CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                      <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu deleveryaddress">2</div>
                      <div class="CardContainer_additionalText__INNXk">Delivery Address</div>
                    </div>
                    <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                      <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu preordertime">3</div>
                      <div class="CardContainer_additionalText__INNXk">Pre-Order Time</div>
                    </div>
                    <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                      <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu payment">4</div>
                      <div class="CardContainer_additionalText__INNXk">Pay</div>
                    </div>
                  </div>
                  <div class="CheckoutProgressPanel_checkoutProgressLineAbs__VYrx3">
                    <div class="CheckoutProgressPanel_checkoutProgressLine__pn7G9"></div>
                  </div>
                </div>
              </section>
            </div>
            <section class="CardContainer_checkoutBox__mHMXn CardContainer_withoutFooter__lkgb0 GeneralInfo_generalInfo__IpkDu CardContainer_checkoutClose__g7tSq">
              <div class="CardContainer_checkoutBoxHeader__JHsWE CardContainer_checkoutShowCursor__Ji0nB">
                <div>
                  <h2 class="CardContainer_checkoutBoxTitle__eisK2">Personal information</h2>
                </div>
                <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk personalup" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);display: none;"  @click="personalinfoupclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam CardContainer_expanded__YrQa5 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5"></path>
                  </svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>

                 <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk personaldown" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);" @click="personalinfodownclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam  " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5" ></path></svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </div>
              <div class="EditableRow_editableRow__yRmbo" v-if="this.userinfoview">

                <i class="GeneralInfo_generalInfoIcon__IVMZR" style="float: left;"></i>
                <p class="CardContainer_sectionText__FdNw9">{{this.firstname}} {{this.lastname}}</p>
                <div class="GeneralInfo_additionalInfo__Ei3CO CardContainer_additionalText__INNXk" style="float:left;">
                  <p class="CardContainer_additionalText__INNXk">{{this.email}}</p>
                  <p class="CardContainer_additionalText__INNXk">{{this.phone}}</p>
                </div>
                <button class="PureButton_pureButton__PmZII EditableRow_editableRowEditButton__u82ux" type="button" aria-label="edit" style="width: 100%;text-align: right;margin-top:-50px;">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="editbuttonon()">
                    <g clip-path="url(#clip0_5513_29370)">
                      <path d="M10.834 20.1016H23.9993" fill="#ffffff" stroke="#ffffff" stroke-opacity=".6" stroke-width="2" stroke-linejoin="round"></path>
                      <path d="M12.5438 2.83608C13.0791 2.30075 13.8052 2 14.5623 2C14.9371 2 15.3083 2.07384 15.6547 2.21729C16.001 2.36075 16.3157 2.57101 16.5807 2.83608C16.8458 3.10115 17.0561 3.41583 17.1995 3.76216C17.343 4.1085 17.4168 4.47969 17.4168 4.85456C17.4168 5.22942 17.343 5.60062 17.1995 5.94695C17.0561 6.29328 16.8458 6.60796 16.5807 6.87303L4.69711 18.7567H0.660156V14.7197L12.5438 2.83608Z" fill="#ffffff" stroke="#ffffff" stroke-opacity="0.3" stroke-width="2" stroke-linecap="round"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_5513_29370">
                        <rect width="24" height="24" fill="#ffffff"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div v-if="this.userinfoedit">
              <div class="EditableRow_editableRow__yRmbo" id="generalinformation">
                <i class="GeneralInfo_generalInfoIcon__IVMZR"></i>
                <p class="CardContainer_sectionText__FdNw9">Enter Personal Information</p>
              </div>
              <form class="GeneralInfo_generalInfoForm__ldfzc">
                <div class="GeneralInfo_generalInfoFormBody__CASVf">
                  <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                    <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                      <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                        <input aria-invalid="false" autocomplete="given-name" id="firstName" placeholder="First name" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic" v-model="firstname">
                      </div>
                    </div>
                  </div>
                  <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                    <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                      <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                        <input aria-invalid="false" autocomplete="family-name" id="lastName" placeholder="Last name" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic" v-model="lastname">
                      </div>
                    </div>
                  </div>
                  <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                    <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                      <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                        <input aria-invalid="false" id="email" placeholder="Email" type="text" inputmode="email" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic" v-model="email">
                      </div>
                    </div>
                  </div>
                  <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                    <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                      <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                        <input aria-invalid="false" id="phoneNumber" placeholder="Phone Number" type="text" inputmode="tel" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic" v-model="phone">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <footer class="CardContainer_checkoutBoxFooter__l3Jdt CardContainer_checkoutBoxButtonsFooter__a1UGs">
                  <button class="Button_button___7IH9 border" type="button" @click.prevent="canceledit()">Cancel</button>
                  <button class="Button_button___7IH9 inverted" type="submit" @click.prevent="updateuserinfo()" >Save</button>
                </footer>
              </form>
            </div>
            </section>

           <section class="CardContainer_checkoutBox__mHMXn DeliveryEdit_delivery__3Sunb CardContainer_checkoutClose__g7tSq">
              <div class="CardContainer_checkoutBoxHeader__JHsWE CardContainer_checkoutShowCursor__Ji0nB">
                <div>
                  <h2 class="CardContainer_checkoutBoxTitle__eisK2">Delivery Address</h2>
                </div>
                <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk deleveryup" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);display: none;"  @click="deleveryinfoupclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam CardContainer_expanded__YrQa5 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5"></path>
                  </svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>

                 <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk deleverydown" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);" @click="deleveryinfodownclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam  " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5" ></path></svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </div>

              <div v-if="this.deleveryedit">
                <div class="DeliveryEdit_deliveryRadioBox__4a3h9">
                  <div>
                    <input id="delivery" name="typeOfDelivery" class="DeliveryEdit_deliveryRadioBoxInput___jfu_" type="radio" checked="">
                    <label class="DeliveryEdit_deliveryRadioBoxLabel__yxmDk" for="delivery">DELIVERY</label>
                  </div>
                </div>
                <div>
                  <div class="DeliveryEdit_spacing__STKuJ">
                    <div>
                      <form class="DeliveryEdit_form__lLldE">
                        <div class="TextField_fieldGroup__fRjeo DeliveryEdit_formAddres1__QeD2Y MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">Address</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input aria-invalid="false" id="address1" placeholder="Address" type="text" v-model="useraddress"  class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo DeliveryEdit_formAddres2__qb06r MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">Apt / Suite</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input aria-invalid="false" id="address2" placeholder="Apt / Suite" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic" v-model="userapt">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo DeliveryEdit_formCity__swnm2 MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">City</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input aria-invalid="false" id="city" placeholder="City" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic"  v-model="usercity">
                            </div>
                          </div>
                        </div>
                        <div class="DeliveryEdit_formState__mYZxJ MuiFormControl-root css-13sljp9">
                         <label class="TextField_fieldLabel__Xh4e9">State</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input aria-invalid="false" id="city" placeholder="City" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic"  v-model="userstate">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo DeliveryEdit_formPostalCode__Ksc6P MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">Postal Code</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input aria-invalid="false" id="postalCode" placeholder="Postal Code" type="text" class="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel css-r7k7ic"  v-model="userpostalcode">
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="DeliveryEdit_spacing__STKuJ"></div>
                      <div class="DeliveryEdit_spacing__STKuJ">
                        <button class="PureButton_pureButton__PmZII DeliveryEdit_changeDeliveryMethod__vQ_uP" type="button" @click="getCurrentLocation()">
                          <div class="DeliveryEdit_deliveryEditInlineBttn___sFzF">
                            <div class="DeliveryEdit_deliveryEditInlineBttnIcon__KnhMM">
                              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="currentColor" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>Use Auto Complete
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
               <br><br>
              <footer class="CardContainer_checkoutBoxFooter__l3Jdt CardContainer_checkoutBoxButtonsFooter__a1UGs">
                <button class="Button_button___7IH9 border" type="button" @click="deleverycancel()">Cancel</button>
                <button class="Button_button___7IH9 inverted" type="button" @click="saveuserinfo()" >Save</button>
              </footer>
              </div>

            </section>
            <section class="CardContainer_checkoutBox__mHMXn DeliveryEdit_delivery__3Sunb CardContainer_checkoutClose__g7tSq preorderdelevery">
              <div class="CardContainer_checkoutBoxHeader__JHsWE CardContainer_checkoutShowCursor__Ji0nB">
                <div>
                  <h2 class="CardContainer_checkoutBoxTitle__eisK2">Pre-Order Delivery Time</h2>
                </div>
                <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk preorderup" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);display: none;"  @click="preorderupclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam CardContainer_expanded__YrQa5 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5"></path>
                  </svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>

                 <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk preorderdown" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);" @click="preorderdownclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam  " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5" ></path></svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </div>

              <div v-if="this.preorderedit">
                <div class="EditableRow_editableRow__yRmbo" id="generalinformation">
                  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
                    <circle cx="100" cy="100" r="95" fill="#fff" stroke="#000" stroke-width="5"/>
                    <text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" font-size="80" font-weight="bold">i</text>
                  </svg>
                  <p class="CardContainer_sectionText__FdNw9">Your Own Pre-Order Delivery Time: {{this.timesting}} </p>
                </div>
                <div>
                  <div class="DeliveryEdit_spacing__STKuJ">
                    <div>
                       <div class="DeliveryEdit_form__lLldE">
                        <div class="TextField_fieldGroup__fRjeo  MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">11:30am to 12:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio"  v-model="preordertime" name="radio-group" value="11:30am to 12:30pm">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                         <label class="TextField_fieldLabel__Xh4e9">12:30pm to 1:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio"  v-model="preordertime" name="radio-group" value="12:30pm to 1:30pm">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo  MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">1:30pm to 2:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio"  v-model="preordertime" name="radio-group" value="1:30pm to 2:30pm">
                            </div>
                          </div>
                        </div>
                      </div>

                       <div class="DeliveryEdit_form__lLldE">

                        <div class="TextField_fieldGroup__fRjeo MuiFormControl-root css-13sljp9">
                          <label class="TextField_fieldLabel__Xh4e9">5:30pm to 6:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio" v-model="preordertime" name="radio-group" value="5:30pm to 6:30pm">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo">
                         <label class="TextField_fieldLabel__Xh4e9">6:30pm to 7:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio"  v-model="preordertime" name="radio-group" value="6:30pm to 7:30pm
">
                            </div>
                          </div>
                        </div>
                        <div class="TextField_fieldGroup__fRjeo">
                          <label class="TextField_fieldLabel__Xh4e9">7:30pm to 8:30pm</label>
                          <div class="MuiFormControl-root TextField_inputField__ugUAn MuiTextField-root css-i44wyl">
                            <div class="MuiFilledInput-root MuiFilledInput-underline MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-hiddenLabel css-q44vsa">
                              <input id="MuiFilledInput-input MuiInputBase-input MuiInputBase-inputHiddenLabel" type="radio"  v-model="preordertime" name="radio-group" value="7:30pm to 8:30pm">
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
               <br><br>
              <footer class="CardContainer_checkoutBoxFooter__l3Jdt CardContainer_checkoutBoxButtonsFooter__a1UGs">
                <button class="Button_button___7IH9 border" type="button" @click="preordercancel()">Cancel</button>
                <button class="Button_button___7IH9 inverted" type="button" @click="preordersave()" >Save</button>
              </footer>
              </div>
            </section>

            <section class="CardContainer_checkoutBox__mHMXn ModulesContainer_modulesBlock__dxsT6 CardContainer_checkoutClose__g7tSq selectpaymentmethod">
              <div class="CardContainer_checkoutBoxHeader__JHsWE CardContainer_checkoutShowCursor__Ji0nB">
                <div>
                  <h2 class="CardContainer_checkoutBoxTitle__eisK2">Payment Methods</h2>
                </div>
                <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk paymentup" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);display: none;"  @click="paymentupclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam CardContainer_expanded__YrQa5 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5"></path>
                  </svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>

                 <button class="MuiButtonBase-root CardContainer_ciExpandButton__PaP93 CardContainer_ciExpandButtonRedefinition__UrKry MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk paymentupdown" tabindex="0" type="button"  style="background:var(--checkoutAccentBackground);" @click="paymentupdownclick()">
                  <svg class="CardContainer_ciExpandIcon__Kjtam  " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5" ></path></svg>
                  <span class="MuiTouchRipple-root css-w0pj6f"></span>
                </button>
              </div>

              
              <div v-show="this.selectpayment">
                <div class="CardContainer_checkoutBoxHeader__JHsWE">
                  <div>
                    <h2 class="CardContainer_checkoutBoxTitle__eisK2">Payment</h2>
                  </div>
                </div>

                <div class="DeliveryEdit_deliveryRadioBox__4a3h9" style="border: 1px solid var(--whiteTranslucent)!important;">
                  <div>
                    <input id="cdo" name="typeOfDelivery" class="DeliveryEdit_deliveryRadioBoxInput___jfu_" type="radio" v-model="selectedpaymentype" checked=""  value="cdo">
                    <label class="DeliveryEdit_deliveryRadioBoxLabel__yxmDk" for="cdo" style="height: 100%!important;">COD</label>
                  </div>
                  <div>
                    <input id="delivery" name="typeOfDelivery" class="DeliveryEdit_deliveryRadioBoxInput___jfu_" type="radio" checked="" v-model="selectedpaymentype" value="stripe">
                    <label class="DeliveryEdit_deliveryRadioBoxLabel__yxmDk" for="delivery" style="height: 100%!important;">Stripe</label>
                  </div>
                </div>
                <br>
                <div v-show="codselected">
                  <p class="CardContainer_sectionText__FdNw9">Cash On Delivery Selected</p>
                </div>
                <div v-show="strypepayment">
                <p class="CardContainer_sectionText__FdNw9">Enter card information</p>
                <div class="PaymentAddNewCard_addNewCardFormBody__G3PMB">
                   
                  <div class="PaymentAddNewCard_ancNumber__O4XrR PaymentAddNewCard_inputWrapper__JeBBC PaymentAddNewCard_inputWrapperStripe__I0Nzs" ref="card"></div> 

                  <div class="PaymentAddNewCard_ancOwner__yD2wj PaymentAddNewCard_inputWrapper__JeBBC">
                    <input placeholder="Name on card" maxlength="200" value="">
                  </div>
                </div>
                <br>
                <footer class="CardContainer_checkoutBoxFooter__l3Jdt CardContainer_checkoutBoxButtonsFooter__a1UGs">
                  <button class="Button_button___7IH9 border" type="button" @click="deleverycancel()">Cancel</button>
                  <button class="Button_button___7IH9 inverted" type="button" @click="paybutton()">Save</button>
                </footer>
               </div>
              </div>
            </section>

              

            <div class="Checkout_checkoutMobileMargin__p2eyH"></div>
          </div>
        </div>
        <div class="Checkout_checkoutAside__dsOIx">
          <div class="CheckoutProgressPanel_checkoutProgressMobile__ORltR">
            <section class="CardContainer_checkoutBox__mHMXn CardContainer_withoutFooter__lkgb0">
              <div class="CardContainer_checkoutBoxHeader__JHsWE">
                <div>
                  <h2 class="CardContainer_checkoutBoxTitle__eisK2">Checkout Progress</h2>
                </div>
              </div>
              <div class="CheckoutProgressPanel_checkoutProgressWrapper__ppaDU">
                <div class="CheckoutProgressPanel_checkoutProgress__W0LWb">
                  <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                    <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu">1</div>
                    <div class="CardContainer_additionalText__INNXk">Personal</div>
                  </div>
                  <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                    <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu deleveryaddress">2</div>
                    <div class="CardContainer_additionalText__INNXk ">Delivery Address</div>
                  </div>
                  <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                    <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu preordertime">3</div>
                    <div class="CardContainer_additionalText__INNXk">Pre-Order Time</div>
                  </div>
                  <div class="CheckoutProgressPanel_checkoutProgressStep__7UjSp">
                    <div class="CheckoutProgressPanel_checkoutProgressStepBubble__IpJuu payment">4</div>
                    <div class="CardContainer_additionalText__INNXk">Pay</div>
                  </div>
                </div>
                <div class="CheckoutProgressPanel_checkoutProgressLineAbs__VYrx3">
                  <div class="CheckoutProgressPanel_checkoutProgressLine__pn7G9"></div>
                </div>
              </div>
            </section>
          </div>
          <div class="CheckoutDetailOrder_checkoutDetailOrder__iNyrQ">
            <div class="CheckoutDetailOrder_cdoOrderBttnMobile__FDLw_">
              <button class="Button_button___7IH9 CheckoutDetailOrder_cdoButton__qAFYZ" @click="order()">Order Now</button>
              <div class="CheckoutDetailOrder_cdoOrderCheckoutMessage__JzDIS">
              
                <div></div>
              </div>
            </div>
            <div class="CheckoutDetailOrder_cdoNofityMobile__VvnpF">
              <div class="NotificationBanner_notifyWrapper__rUD65 NotificationBanner_notifyWrapperClose__3QHye">
                <div class="NotificationBanner_notifyIcon__Oy0_v">
                  <span></span>
                </div>
                <div class="NotificationBanner_notifyComponent__g818G"></div>
              </div>
            </div>
            <div class="CheckoutDetailOrder_cdoWrapper__1M6fd">
              <div class="CheckoutDetailOrder_cdoWrapperCheckoutItems__mJrtG">

                    <div class="CheckoutItems_checkoutItemsWrapper__agMQx MuiBox-root css-0">
                    <header class="CheckoutItems_checkoutItemsHeader__WBc9q">
                      <h3 class="CheckoutItems_checkoutItemsTitle__OMVrz">Your Items</h3>
                      <button class="MuiButtonBase-root CheckoutItems_ciExpandButton__kwE1C CheckoutItems_ciExpandButtonRedefinition__SWJI4 MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk" tabindex="0" type="button">
                        <svg class="CheckoutItems_ciExpandIcon__WbPLp CheckoutItems_expanded__3QE7l" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 6L8 10L12 6" stroke="white" stroke-width="1.5"></path>
                        </svg>
                        <span class="MuiTouchRipple-root css-w0pj6f"></span>
                      </button>
                    </header>
                    <div class="CheckoutItems_ciContentWrapper__DH7Gn CheckoutItems_expanded2__1ABy5">
                      <div>
                        <div class="CartList_cartDrawerBody__nlRpB">
                          <div class="CartList_cartDrawerSection__ZXVJb CartList_isCheckout__AVyCX">
                            <div class="CartList_cartDrawerSectionHeader___ffE_">
                              <div class="CartList_cartDrawerSectionHeaderImgWrapper__UJOpt">
                                <span style="box-sizing: border-box; display: inline-block; overflow: hidden; width: 36px; height: 36px; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative;">
                                  <img alt="tastemade-metacos Logo" srcset="/_next/image?url=https%3A%2F%2Fgobycitizens.imgix.net%2Frest_6e3c7c39ce7b4c429c687bf4f02f1216.icon.white.png%3Fauto%3Dformat&amp;w=48&amp;q=75 1x, /_next/image?url=https%3A%2F%2Fgobycitizens.imgix.net%2Frest_6e3c7c39ce7b4c429c687bf4f02f1216.icon.white.png%3Fauto%3Dformat&amp;w=96&amp;q=75 2x" src="/_next/image?url=https%3A%2F%2Fgobycitizens.imgix.net%2Frest_6e3c7c39ce7b4c429c687bf4f02f1216.icon.white.png%3Fauto%3Dformat&amp;w=96&amp;q=75" decoding="async" data-nimg="fixed" class="CartList_cartDrawerSectionHeaderImg__CkBek" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;">
                                </span>
                              </div>
                            </div>
                            <div class="CartList_cartDrawerSectionItems__LzROF"  >

                             <template v-for="(i,index) in this.cart.length">

                              <div class="CartCard_cartCard__4pcZS" style="position:relative;">
                                <svg style="position: absolute;right: -170px;color: white;width: 100%;top:12px;cursor:pointer;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent="removeProductFromCart(this.cart[index].product)"><path  d="M0.318192 0.318191C0.521926 0.114457 0.798249 6.71557e-08 1.08637 6.71557e-08C1.3745 6.71557e-08 1.65082 0.114457 1.85455 0.318191L15.6818 14.1454C15.8855 14.3492 16 14.6255 16 14.9136C16 15.2018 15.8855 15.4781 15.6818 15.6818C15.4781 15.8855 15.2018 16 14.9136 16C14.6255 16 14.3492 15.8855 14.1454 15.6818L0.318192 1.85455C0.114458 1.65082 1.15387e-06 1.3745 1.15387e-06 1.08637C1.15387e-06 0.798248 0.114458 0.521925 0.318192 0.318191Z" fill="currentColor"></path><path d="M15.6818 0.318191C15.8855 0.521925 16 0.798248 16 1.08637C16 1.3745 15.8855 1.65082 15.6818 1.85455L1.85455 15.6818C1.65082 15.8855 1.3745 16 1.08637 16C0.798248 16 0.521926 15.8855 0.318191 15.6818C0.114457 15.4781 -1.25864e-08 15.2017 0 14.9136C1.26022e-08 14.6255 0.114457 14.3492 0.318191 14.1454L14.1454 0.318191C14.3492 0.114457 14.6255 -1.25943e-08 14.9136 0C15.2018 1.25943e-08 15.4781 0.114457 15.6818 0.318191Z" fill="currentColor"></path></svg>
                                
                                <div  class="CartCard_cartImg__hqB27" v-bind:style="{'background-image':'url(/images/menuitemimages/'+this.cart[index].product.imagename + ')' }"></div>
                                <div class="CartCard_cartCardBody__qu09I">
                                  <div class="CartCard_cartCardInfo__buIjj">
                                    <div class="CartCard_cartCardInfoTitle__OayJ_" v-if="this.cart.length>0" >{{this.cart[index].product.name}}</div>
                                    <div class="CartCard_cartCardInfoDesc__cwZg2" v-if="this.cart.length>0"><span v-for="item in this.cart[index].productoption">{{item.name}},</span></div>
                                  </div>
                                  <div class="CartCard_cartCardRightBottom__R4q_q CartCard_cartCardRightBottomCheckout__2qj0t">
                                    <span></span>
                                    <div class="CartCard_cartCardPrice__3yXJy">{{this.cart[index].quantity}} X ${{this.cart[index].product.unit_price}}</div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div>
                <div class="CheckoutDetailOrder_cdoBlock__RLVM0">
                  <span class="CheckoutDetailOrder_cdoSubtitle__S0cb3">Tip</span>
                  <div class="CheckoutDetailOrder_cdoTipOptions__nvh73">
                    <button class="Button_button___7IH9 CheckoutDetailOrder_cdoTipOption__TqQ55 one" @click="addtip(1)">$1</button>
                    <button class="Button_button___7IH9 CheckoutDetailOrder_cdoTipOption__TqQ55 three" @click="addtip(3)">$ 3</button>
                    <button class="Button_button___7IH9 CheckoutDetailOrder_cdoTipOption__TqQ55 five" @click="addtip(5)">$ 5</button>
                    <button class="Button_button___7IH9 CheckoutDetailOrder_cdoTipOption__TqQ55 canceltip" @click="addtip(0)">X</button>
                  </div>
                </div>
                <div class="CheckoutDetailOrder_cdoBlockBorder__3aD2O">
                  <!-- <div class="CheckoutDetailOrder_cdoPositiveNotify__gCskp">Checkout in the next 5 minutes to get 20% off your order!</div> -->
                  <div class="CheckoutDetailOrder_cdoPositiveNotifyBorder__a4nSZ CheckoutDetailOrder_cdoBlock__RLVM0 CheckoutDetailOrder_cdoPromoCodeBlock__1KoCf">
                    <span class="CheckoutDetailOrder_cdoSubtitle__S0cb3">Promo Code</span>
                    <div class="CheckoutDetailOrder_cdoDiscountWrapper__kLyZQ">
                      <div class="MuiFormControl-root CheckoutDetailOrder_cdoDiscountTextField__WQ1fh CheckoutDetailOrder_cdoDiscountTextFieldRedefinition__zuAtQ CheckoutDetailOrder_cdoTextField__KZne1 CheckoutDetailOrder_cdoTextFieldRedefinition__U6x9M CheckoutDetailOrder_cdoTipTextFieldPromo__bH9iq MuiTextField-root css-i44wyl">
                        <div class="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl css-1v4ccyo">
                          <input aria-invalid="false" placeholder="Enter a promo code" type="text" class="MuiOutlinedInput-input MuiInputBase-input css-1x5jdmq" v-model="couponcode" id="mui-2">
                          <fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-igs3ac">
                            <legend class="css-hdw1oc">
                              <span class="notranslate">​</span>
                            </legend>
                          </fieldset>
                        </div>
                      </div>
                      <button class="Button_button___7IH9 secondary CheckoutDetailOrder_cdoDiscountButton___klaZ CheckoutDetailOrder_cdoDiscountButtonRedefinition__D__Vy" @click="addcoupon()">Apply</button>
                    </div>
                  </div>
                </div>
                <div class="CheckoutDetailOrder_cdoInfo__YFvru">
                  <div class="CheckoutDetailOrder_cdoInfoRow__0qKv9">
                    <span class="CheckoutDetailOrder_cdoInfoTitle__B2IgX">Subtotal</span>
                    <span class="CheckoutDetailOrder_cdoInfoValue__qur_E">${{this.totalprice}}</span>
                  </div>
                  <div class="CheckoutDetailOrder_cdoInfoRow__0qKv9">
                    <span class="CheckoutDetailOrder_cdoInfoTitle__B2IgX">Tax ({{this.taxpercentage}}%)</span>
                    <span class="CheckoutDetailOrder_cdoInfoValue__qur_E">${{this.tax}}</span>
                  </div>
                  <div class="CheckoutDetailOrder_cdoInfoRow__0qKv9">
                    <span class="CheckoutDetailOrder_cdoInfoTitle__B2IgX">Shipping ({{this.totaldistance}} Km)</span>
                    <span class="CheckoutDetailOrder_cdoInfoValue__qur_E">${{this.deliverycharge}}</span>
                  </div>
                  <div class="CheckoutDetailOrder_cdoInfoRow__0qKv9">
                    <span class="CheckoutDetailOrder_cdoInfoTitle__B2IgX">Discount</span>
                    <span class="CheckoutDetailOrder_cdoInfoValue__qur_E">${{this.discount}}</span>
                  </div>
                  <div class="CheckoutDetailOrder_cdoInfoRow__0qKv9">
                    <span class="CheckoutDetailOrder_cdoInfoTitle__B2IgX">Tip</span>
                    <span class="CheckoutDetailOrder_cdoInfoValue__qur_E">${{this.tip}}</span>
                  </div>
                </div>
                <div class="CheckoutDetailOrder_cdoTotal__JYfIy">
                  <span>Total</span>
                  <span>${{totalamountcalculation()}}</span>
                </div>
                <div class="CheckoutDetailOrder_cdoOrderBttn__OkF2z">
                  <div></div>
                  <button class="Button_button___7IH9 CheckoutDetailOrder_cdoButton__qAFYZ" @click="order()">Order Now</button>
                </div>
                <div class="CheckoutDetailOrder_cdoNofityDesktop__cSo1S">
                  <div class="NotificationBanner_notifyWrapper__rUD65 NotificationBanner_notifyWrapperClose__3QHye">
                    <div class="NotificationBanner_notifyIcon__Oy0_v">
                      <span></span>
                    </div>
                    <div class="NotificationBanner_notifyComponent__g818G"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
</template>
<style>

	.EditableRow_editableRow__yRmbo{
		display: none;
	}
	.Checkout_checkoutHeader__2XZIL{
	    cursor:pointer;
	}
    .personaldown{
      display: block;
    }
    .personalup{
    	display: none;
    }
    .deleveryup{
      display: none;
    }
    .deleverydown{
      display: block;
    }
    .preorderup{
       display: none;
     }
     .preorderdown{
        display: block;
     }
     .paymentup{
       display: none;
     }
     .paymentupdown{
      display: block;
     }
    .CheckoutDetailOrder_cdoWrapper__1M6fd {
    height:auto;
    overflow-y: scroll;

   }

.CheckoutDetailOrder_cdoWrapperCheckoutItems__mJrtG {
    display: block;
    overflow: auto;
    justify-content: flex-start;
}
.CheckoutItems_checkoutItemsWrapper__agMQx {
    height:120px;

}
.CheckoutItems_ciContentWrapper__DH7Gn {
    height: auto;
    overflow-y: scroll;
}
.CheckoutItems_checkoutItemsHeader__WBc9q {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    border-bottom: 1px solid var(--whiteTranslucent);
    transition: border-bottom 0s ease-in-out .2s;
}

#generalinformation{
  display: inline-flex!important;
}

.DeliveryEdit_deliveryRadioBox__4a3h9 {
    border: none!important;
}

.DeliveryEdit_form__lLldE {
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    grid-template:
        "address1 address1 address1 address1 address2 address2"
        "city city state state postalCode postalCode"/1fr 1fr 1fr;
}

.StripeElement{
  box-sizing: border-box;
  height: 56px;;
  background-color: var(--inputBackground)!important;
  -webkit-transtion:box-shadow 150ms ease;
}
.StripeElement--invalid{
  border-color:#fa755a;
}
.StripeElement--webkit-autofill{
  background-color: #fff!important;
}
</style>
<script>
import $ from 'jquery';
import { ref, onMounted,watch,inject,defineProps,getCurrentInstance } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { useStore } from 'vuex';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
var stripe=Stripe(
  "pk_test_51N9howDdqBow8ibYGFjiu9UGYHl1uwZMMZapG64QN9vx4h6EIJqr8E08uE9XroybxmSH9rygGgQ3QV9tSvB3Q9OT000FFq99U4");
var elements=stripe.elements();
var style={
   base:{
     color:"#fff",
     fontSize: "16px",
     "::placeholder":{
      color:"#fff"
     },
   },
   invalid:{
    color:'#fa755a',
    iconColor:"#fa755a",
   }
};
var card=undefined;
var moment = require('moment');
import Swal from 'sweetalert2';
export default{
  setup(props, context) {
    const currentAddress = ref(null);
    const currentCity = ref(null);
    const currentPostalCode = ref(null);
    const currentState = ref(null);
    const store = useStore();
    const distance = ref(0);
    const restaurantlat = props.restaurantlat;
    const restaurantlong = props.restaurantlong;
    const charge_per_kilo = props.charge_per_kilo;
    const basic_delivery_charge= props.basic_delivery_charge;
    const free_delivery_radius= props.free_delivery_radius;
    const instance = getCurrentInstance();
    const loader = new Loader({
        apiKey: 'AIzaSyCB0EkwqGYRfYtRsZkoGmrtsHGtWtSGuQE',
        version: 'weekly',
        libraries: ['places','geometry'],
    });
    
    function getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            loader.load().then(() => {
              const google = window.google; // Access the google object from the window

              const geocoder = new google.maps.Geocoder();
              const latLng = new google.maps.LatLng(latitude, longitude);

              geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (results[0]) {
                    const addressComponents = results[0].address_components;
                    currentAddress.value = results[0].formatted_address;
                    currentCity.value = getComponentValue(addressComponents, 'locality');
                    currentPostalCode.value = getComponentValue(addressComponents, 'postal_code');
                    currentState.value = getComponentValue(addressComponents, 'administrative_area_level_1');
                    store.dispatch('Addaddress',{address:currentAddress.value});
                    store.dispatch('Addcity',{city:currentCity.value});
                    store.dispatch('Addstate',{newstate:currentState.value});
                    store.dispatch('Addpostalcode',{postalcode:currentPostalCode.value});

                    var point1 = new google.maps.LatLng(restaurantlat, restaurantlong);
                    var point2 = new google.maps.LatLng(latitude, longitude);
                    distance.value = google.maps.geometry.spherical.computeDistanceBetween(point1, point2)/ 1000;
                     var deliverycharge=0;
                     var totaldistance=Math.round(distance.value);
                     if(free_delivery_radius>=totaldistance){
                       deliverycharge=parseFloat(basic_delivery_charge);
                     }else{
                      var d=parseFloat(totaldistance)-parseFloat(free_delivery_radius);
                      var charge=parseFloat(charge_per_kilo)*parseFloat(d);
                      deliverycharge=parseFloat(basic_delivery_charge)+parseFloat(charge);
                      
                     }
                     store.dispatch('deliverycharge',{deliverycharge:deliverycharge});
                     store.dispatch('totaldistance',{totaldistance:totaldistance});

                     $(".preordertime").addClass('CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu');
                    
                    instance.proxy.updateuserpersonalinfo(currentAddress.value,currentCity.value,currentState.value,currentPostalCode.value);
                  } else {
                    console.error('No results found.');
                  }
                } else {
                  console.error('Geocoder failed due to: ' + status);
                }
              });
            });
          },
          error => {
            console.error('Error getting current location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }

    function getComponentValue(components, type) {
      const component = components.find(component => component.types.includes(type));
      return component ? component.long_name : null;
    }

    return {
      currentAddress,
      currentCity,
      currentPostalCode,
      currentState,
      getCurrentLocation,
      distance,
      restaurantlat,
      restaurantlong,
      charge_per_kilo,
      basic_delivery_charge,
      free_delivery_radius,
    };
  },
   props:['restaurantlat','restaurantlong','promocode','deleverytime','userinfo','charge_per_kilo','basic_delivery_charge','free_delivery_radius'],
   data(){
     return{
       preordertime:null,
       discount:0,
       totalamount:0,
       tip:0,
       taxpercentage:7,
       tax:0,
       couponcode:'',
       moment: moment,
       userinfoview:true,
       userinfoedit:false,
       deleveryedit:false,
       preorderedit:false,
       selectpayment:false,
       codselected:false,
       strypepayment:true,
       currentLocation: null,
       useraddress:'',
       userapt:'',
       usercity:'',
       userstate:'',
       userpostalcode:'',
       timesting:null,
       addressvalue:[
         
       ],
       stripetoken:'',
       firstname:'',
       lastname:'',
       email:'',
       phone:'',
       useraddress:'',
       userid:'',
       selectedpaymentype:'',

       }
   },

   mounted(){

     this.tax=(this.totalprice*this.taxpercentage)/100;
     this.totalamountcalculation();
     this.useraddress=this.address.address;
     this.userapt=this.adduserapt.userapt;
     this.usercity=this.addcity.city;
     this.userstate=this.addstate.newstate;
     this.userpostalcode=this.addpostalcode.postalcode;
      card=elements.create("card",{
       style:style,
       hidePostalCode:true

       });
      card.mount(this.$refs.card);
      this.firstname=this.userinfo.first_name;
      this.lastname=this.userinfo.last_name;
      this.email=this.userinfo.email;
      this.phone=this.userinfo.phone;
      this.userid=this.userinfo.id;
      this.useraddress=this.userinfo.address;
      if(this.deliverycharge>0){
         $(".deleveryaddress").addClass('CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu');
      }
      if(this.timesting!=null){
        $(".preordertime").addClass('CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu');
      }
      //payment
   },
   computed:{
        cart(){
          return this.$store.state.cart;
        },
        address(){
          return this.$store.getters.address;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        },
        deliverycharge(){
         return this.$store.getters.deliverycharge;
        },
        totaldistance(){
         return this.$store.getters.totaldistance;
        },
        address(){
         return this.$store.getters.address;
        },
        addstate(){
         return this.$store.getters.addstate;
        },
        addcity(){
         return this.$store.getters.addcity;
        },
        addpostalcode(){
          return this.$store.getters.addpostalcode;
        },
        adduserapt(){
          return this.$store.getters.adduserapt;
        }

    },
    methods:{
    totalamountcalculation(){
        this.totalamount=(this.totalprice+this.tax+this.tip+this.deliverycharge)-this.discount;
        return this.totalamount;
    },
    submit () {
      this.$refs.checkoutRef.redirectToCheckout();
    },
	  home(){
        window.location.replace(this.url);
	  },
    updateuserinfo(){
      axios.post('updateuserinfo',{
        userid:this.userid,
        firstname:this.firstname,
        lastname:this.lastname,
        email:this.email,
        phone:this.phone

      }).then(response=>{
        console.log(response);
        this.firstname=response.data.first_name;
        this.lastname=response.data.last_name;
        this.email=response.data.email;
        this.phone=response.data.phone;

        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'User Info Updated successfully',
            showConfirmButton: false,
            timer: 1500
        });
        



      })
    
    },
    useautocomplete(){
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const geocoder = new google.maps.Geocoder();
            const latLng = new google.maps.LatLng(latitude, longitude);

            geocoder.geocode({ location: latLng }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  this.currentAddress = results[0].formatted_address;
                } else {
                  console.error('No results found.');
                }
              } else {
                console.error('Geocoder failed due to: ' + status);
              }
            });
          },
          error => {
            console.error('Error getting current location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    },
    canceledit(){
      this.userinfoview=true;
      this.userinfoedit=false;
    },
    removeProductFromCart(productitem){

          this.$store.dispatch('removeProductFromCart',productitem);
          this.tax=(this.totalprice*this.taxpercentage)/100;
          this.totalamountcalculation();

    },
    updateuserpersonalinfo(address,city,state,poscode){
        this.useraddress=address;
        this.usercity=city;
        this.userstate=state;
        this.userpostalcode=poscode;
     },
    saveuserinfo(){
        this.$store.dispatch('Addaddress',{address:this.useraddress});
        this.$store.dispatch('Addcity',{city:this.usercity});
        this.$store.dispatch('Addstate',{newstate:this.userstate});
        this.$store.dispatch('Addpostalcode',{postalcode:this.userpostalcode});
        this.$store.dispatch('Addapt',{userapt:this.userapt});
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'User Info Updated successfully',
            showConfirmButton: false,
            timer: 1500
        });
    },
    editbuttonon(){
      this.userinfoview=false;
      this.userinfoedit=true;
    },
    addcoupon(){
      if(this.couponcode==this.promocode.slug){
          var today=moment().format('YYYY-MM-DD HH:mm:ss');
          let foundDateInRange = moment(today).isBetween(this.promocode.from_date,this.promocode.to_date);

          if(foundDateInRange==false){
             
              Swal.fire(
                'Coupons Expired',
                'coupon  has expired',
                'question'
              );


          }else{

            if(this.promocode.limit>0){
               axios.get('checkusercouponlimit/'+this.promocode.id).then(response=>{
                   if(response.data>0){
                      Swal.fire(
                       'Opps!',
                       'You have already used this coupon',
                       'question'
                        );
                   }else{
                      
                      if(this.promocode.discount_type==5){
                         //alert("fixed amount");
                         this.discount=this.promocode.amount;
                         this.tip;
                         this.tax=(this.totalprice*this.taxpercentage)/100;
                         this.totalamountcalculation();
                         Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title: 'Congratulations! You have won a Discount Coupon from'+this.couponcode+'!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                      }else{
                         var percentage=this.promocode.amount;
                         this.discount=(parseFloat(this.totalprice)*parseFloat(percentage))/100;
                         this.tip;
                         this.tax=(this.totalprice*this.taxpercentage)/100;
                         this.totalamountcalculation();
                         Swal.fire({
                          position: 'top-end',
                          icon: 'success',
                          title: 'Congratulations! You have won '+percentage+'% Discount Coupon from '+this.couponcode+'!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                      }
                   }
               });

            }else{
              Swal.fire(
                'Coupons Limit',
                'insufficient coupons',
                'question'
              );
             
            }


          }
          

      }else{
          Swal.fire(
              'Opps!',
              'This coupon code is invalid',
              'question'
          );
      }
    },
	  personalinfoupclick(){
	  	$('.GeneralInfo_generalInfo__IpkDu').addClass('CardContainer_checkoutClose__g7tSq'); 
	  	$('.EditableRow_editableRow__yRmbo').css('display','none');
	  	$('.personalup').css('display','none');
      $('.personaldown').css('display','block');
      this.userinfoview=true;
      this.userinfoedit=false;
	  },
    deleveryinfoupclick(){
       $('.DeliveryEdit_delivery__3Sunb').addClass('CardContainer_checkoutClose__g7tSq'); 
       this.deleveryedit=false;
       $('.deleveryup').css('display','none');
       $('.deleverydown').css('display','block');
    },
    deleveryinfodownclick(){
       $('.DeliveryEdit_delivery__3Sunb').removeClass('CardContainer_checkoutClose__g7tSq'); 
       this.deleveryedit=true;
       $('.deleveryup').css('display','block');
       $('.deleverydown').css('display','none');

      
    },
    preorderupclick(){
       $('.preorderdelevery').addClass('CardContainer_checkoutClose__g7tSq'); 
       this.preorderedit=false;
       $('.preorderup').css('display','none');
       $('.preorderdown').css('display','block'); 
    },
    preorderdownclick(){
       $('.preorderdelevery').removeClass('CardContainer_checkoutClose__g7tSq'); 
       this.preorderedit=true;
       $('.preorderup').css('display','block');
       $('.preorderdown').css('display','none');
    },

     paymentupclick(){
       $('.selectpaymentmethod').addClass('CardContainer_checkoutClose__g7tSq'); 
       this.selectpayment=false;
       $('.paymentup').css('display','none');
       $('.paymentupdown').css('display','block');
     },
     paymentupdownclick(){
       $('.selectpaymentmethod').removeClass('CardContainer_checkoutClose__g7tSq'); 
       this.selectpayment=true;
       $('.paymentup').css('display','block');
       $('.paymentupdown').css('display','none');
     },
     deleverycancel(){
       $('.DeliveryEdit_delivery__3Sunb').addClass('CardContainer_checkoutClose__g7tSq'); 
       this.deleveryedit=false;
       $('.deleveryup').css('display','none');
       $('.deleverydown').css('display','block');
    },
	  personalinfodownclick(){
        $('.GeneralInfo_generalInfo__IpkDu').removeClass('CardContainer_checkoutClose__g7tSq'); 
	  	  $('.EditableRow_editableRow__yRmbo').css('display','block');
	  	  $('.personalup').css('display','block');
        $('.personaldown').css('display','none');
	  },
    preordercancel(){
       $('.preorderdelevery').addClass('CardContainer_checkoutClose__g7tSq'); 
       this.preorderedit=false;
       $('.preorderup').css('display','none');
       $('.preorderdown').css('display','block'); 
    },
    preordersave(){
      if(this.timesting==null){
          Swal.fire(
              'Opps!',
              'Please select a pre-order time',
              'question'
          );
      }
    },
    addtip(tripamount){

       if(tripamount==1){
        this.tip=tripamount;
        this.totalamountcalculation();
        $('.one').addClass('CheckoutDetailOrder_active__f5FIn');
        $('.three').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.five').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.canceltip').removeClass('CheckoutDetailOrder_active__f5FIn');
       }
       if(tripamount==3){
        this.tip=tripamount;
        this.totalamountcalculation();
        $('.three').addClass('CheckoutDetailOrder_active__f5FIn');
        $('.one').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.five').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.canceltip').removeClass('CheckoutDetailOrder_active__f5FIn');
       }
       if(tripamount==5){
        this.tip=tripamount;
        this.totalamountcalculation();
        $('.five').addClass('CheckoutDetailOrder_active__f5FIn');
        $('.one').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.three').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.canceltip').removeClass('CheckoutDetailOrder_active__f5FIn');
       }
       if(tripamount==0){
        this.tip=tripamount;
        this.totalamountcalculation();
        $('.canceltip').addClass('CheckoutDetailOrder_active__f5FIn');
        $('.five').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.one').removeClass('CheckoutDetailOrder_active__f5FIn');
        $('.three').removeClass('CheckoutDetailOrder_active__f5FIn');

       }
      
    },
    paybutton(){
         stripe.createToken(card).then(result=>{
           if(result.error){
             console.log(result.error)
           }else{
             this.handelToken(result.token);
           }
         })
       },
       handelToken(token){
         this.stripetoken=token.id;
         axios.post('stripepayment',{
           stripeToken:this.stripetoken,
           amount:10,
           username:'syed naim',
           email:'sdnaim92@gmail.com'
         }).then(response=>{
            console.log(response.data);
         })
       },
        order(){
        if(this.timesting==null){
        this.$swal.fire({
          title: 'you missed something.',
          text: 'Pre-Order delivery time is require', 
          icon: 'error',
          confirmButtonText: 'Got it'
         })
        }
        else if(this.deliverycharge==0){
          this.$swal.fire({
            title: 'you missed something.',
            text: 'Delivery address is not completed', 
            icon: 'error',
            confirmButtonText: 'Got it'
           })
        }
        else if(this.totalprice==0){
          this.$swal.fire({
            title: 'you missed something.',
            text: 'Your cart is empty', 
            icon: 'error',
            confirmButtonText: 'Got it'
           })
        }
        
        else{
          
            let newObj= {"address":this.useraddress+','+this.usercity+','+this.userstate+','+this.userpostalcode,"apartment": this.userapt}
            this.addressvalue.push(newObj);
            axios.post('storeorder',{

                cart:this.cart,
                subtotal:this.totalprice,
                discount:this.discount,
                total:this.totalamount,
                delevery:this.deliverycharge,
                delivery_time:this.timesting,
                address:this.addressvalue[0],
                phone:'+1 (305) 300-1805',

            }).then(response=>{
                console.log(response.data);
                this.$swal.fire({
                  title: 'Congratulations',
                  text: ' your order has been successfully completed!', 
                  icon: 'success',
                  confirmButtonText: 'Ok'
                 });
                
                window.location.replace(this.url+'account/order/'+response.data);

            })

        }
    }
   },
  watch:{
        preordertime(value){
            var day=this.deleverytime/24;
            this.timesting=moment().add(day, 'days').format("MMM Do YY")+' '+value;
        
        },
        timesting(value){
          $(".preordertime").addClass('CheckoutProgressPanel_checkoutProgressStepBubbleComplete__u6oWu');
        },
        selectedpaymentype(value){
          if(value=='cdo'){
             this.codselected=true;
             this.strypepayment=false;
          }
          if(value=='stripe'){
           this.strypepayment=true;
           this.codselected=false;
          }
        }

    },

}
</script>