<template>
	  <div class="FAQ_faqQuestionsWrapper__v6MxX MuiBox-root css-0">
	    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
        <div class="ExpandBlock_expandBlock__7Nnw3">(Effective May 20, 2014)<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Notice Regarding Dispute Resolution: These Terms of Use contain provisions that govern how claims you and we may have against each other are resolved (see section O below), including an agreement and obligation to arbitrate disputes, which will, subject to limited exceptions, require you to submit to claims you have against us to binding arbitration, unless you opt out in accordance with section O(6). Unless you opt-out of arbitration: (a) you will only be permitted to pursue claims against us on an individual basis, not as part of any class or representative action or proceeding and (b) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.</p>
      </div>
    </div>


    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">A. INTRODUCTION AND ACCEPTANCE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">These Terms of Use (as amended from time to time, the “Terms of Use”) apply to all websites, devices and applications that collect personal information from you that Creating Culinary Communities, LLC, SBE Restaurant Group, LLC d/b/a Disruptive Restaurant Group or our successors, parents, subsidiaries, affiliates, related companies, and other controlled affiliates (collectively, “we”, “us” or “our”) operate (either directly or through our service providers) that link to these Terms of Use, web pages within each such website, device or application, and web pages that are associated with each such website, device or application (the “Website”).
PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE. BY ACCESSING AND/OR USING THE WEBSITE (OTHER THAN TO READ THESE TERMS OF USE FOR THE FIRST TIME) YOU ARE AGREEING TO COMPLY WITH THESE TERMS OF USE, WHICH MAY CHANGE FROM TIME TO TIME AND WILL BE DEEMED EFFECTIVE AS SET FORTH IN SECTION P BELOW. IF YOU USE THE WEBSITE AFTER CHANGES TO THESE TERMS OF USE BECOME EFFECTIVE SUCH USE WILL SIGNIFY YOUR AGREEMENT TO BE BOUND BY THE CHANGES. YOU SHOULD CHECK BACK FREQUENTLY AND REVIEW THESE TERMS OF USE REGULARLY SO YOU ARE AWARE OF THE MOST CURRENT RIGHTS AND OBLIGATIONS THAT APPLY TO YOU. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, DO NOT ACCESS OR USE THE WEBSITE.
You agree that these Terms of Use are supported by good and valuable consideration the receipt and sufficiency of which you hereby acknowledge. Such consideration includes, without limitation, your use of the Website and the materials and information available on the same and the possibility of publication or publicity of your User Content, as “User Content” is defined below.
In addition to these Terms of Use, we have established a Privacy Policy to explain how we collect and use certain information about you through certain of our websites. A copy of this Privacy Policy can be found at //www.sbe.com//privacy-policy and is incorporated by reference into these Terms of Use. By accessing or using the Website, you are also signifying your acknowledgement and agreement to that Privacy Policy.
The Website is only made available to users 21 years of age or older. If you are not 21 years old, please discontinue using the Website immediately, or if for any reason, you do not agree with all of the terms and conditions contained in these Terms of Use, please discontinue using the Website immediately. By using or attempting to use the Website, you certify that you are at least 21 years of age and meet any other eligibility and residency requirements of the Website.
If you are located in the European Union or elsewhere outside of the United States, your use of this Website is at your own risk and initiative and you, not us, are responsible for compliance with any applicable local and national laws. By using this Website, participating in any Website activities, and/or providing us with your personal information, you (i) consent to the transfers and processing of any information you provide to the Website; (ii) acknowledge that U.S. law provides a lower standard of protection for personal data than the laws of various countries including, but not limited to, the European Union; and (iii) understand that this Website will deal with your information in accordance with our Privacy Policy and U.S. law. Consequently, you hereby waive any claims that may arise under the laws and regulations that apply to you in any other country or jurisdiction.
</p>
      </div>
    </div>
    <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">B. INTELLECTUAL PROPERTY<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">The Website and included content (and any derivative works or enhancements of the same) including, but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos, sounds, music, videos, information, content, materials, products, services, URLs, technology, documentation, and interactive features (collectively, the “Website Content”) and all intellectual property rights to the same are owned by us, our licensors, or both. Additionally, all trademarks, service marks, trade names and trade dress that may appear on the Website are owned by us, our licensors, or both. Except for the limited use rights granted to you in these Terms of Use, you shall not acquire any right, title or interest in the Website or any Website Content. Any rights not expressly granted in these Terms of Use are expressly reserved.</p>
      </div>
    </div>
    
        <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">C. WEBSITE ACCESS AND USE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">Access to the Website including, without limitation, the Website Content is provided for your information and personal, non-commercial use only. When using the Website, you agree to comply with all applicable federal, state, and local laws including, without limitation, copyright law. Except as expressly permitted in these Terms of Use, you may not use, reproduce, distribute, create derivative works based upon, publicly display, publicly perform, publish, transmit, or otherwise exploit Website Content for any purpose whatsoever without obtaining prior written consent from us or, in the case third-party content, its respective owner. In certain instances, we may permit you to download or print Website Content or both. In such a case, you may download or print (as applicable) one copy of Website Content for your personal, non-commercial use only. You acknowledge that you do not acquire any ownership rights by downloading or printing Website Content.
Furthermore, except as expressly permitted in these Terms of Use, you may not:
•remove, alter, cover, or distort any copyright, trademark, or other proprietary rights notice on the Website or Website Content;
•circumvent, disable or otherwise interfere with security-related features of the Website including, without limitation, any features that prevent or restrict use or copying of any content or enforce limitations on the use of the Website or Website Content;
•use an automatic device (such as a robot or spider) or manual process to copy or “scrape” the Website or Website Content for any purpose without our express written permission. Notwithstanding the foregoing, we grant public search engine operators permission to use automatic devices (such as robots or spiders) to copy Website Content from the Website for the sole purpose of creating (and only to the extent necessary to create) a searchable index of Website Content that is available to the public. We reserve the right to revoke this permission (generally or specifically) at any time;
•transmit, distribute or upload programs or material that contain malicious code, such as viruses, timebombs, cancelbots, worms, trojan horses, spyware, or other potentially harmful programs or other material or information;
•forge any TCP/IP packet header or part of the header information in any email or newsgroup posting for any reason;
•collect or harvest any personally identifiable information from the Website including, without limitation, user names, passwords, or email addresses;
•solicit other users to join or become members of any commercial online service or other organization without our prior written approval;
•attempt to or interfere with the proper working of the Website or impair, overburden, or disable the same;
•decompile, reverse engineer, or disassemble any portion of any the Website;
•use network-monitoring software to determine architecture of or extract usage data from the Website;
•encourage conduct that violates any local, state or federal law, either civil or criminal, or impersonate another user, person, or entity (e.g., using another person’s Account (as “Account” is defined below without permission, etc.);
•affect us adversely or reflect negatively on us, the Website, our goodwill, name or reputation or cause duress, distress or discomfort to us or anyone else, or discourage any person, firm or enterprise from using all or any portion, features or functions of the Website, or from advertising, linking or becoming a supplier to us in connection with the Website;
•violate U.S. export laws, including, without limitation, violations of the Export Administration Act and the Export Administration Regulations administered by the Department of Commerce; or
•engage in any conduct that restricts or inhibits any other user from using or enjoying the Website.
Furthermore, you agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of these Terms of Use.
</p>
      </div>
    </div>

        <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">D. USER REGISTRATION<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">In order to access or use some features of the Website, you may have to become a registered user. If you are under the age of 21, then you are not permitted to register as a user or otherwise submit personal information.
If you become a registered user, you agree that you will provide true, accurate and complete registration information and, if such information changes, you will promptly update the relevant registration information. During registration, you will create a user name and password (a “Account”), which may permit you access to certain areas of the Website not available to non-registered users. You are responsible for safeguarding and maintaining the confidentiality of your Account. You are solely responsible for the activity that occurs under your Account, whether or not you have authorized the activity. You agree to notify us immediately at privacy@sbe.com of any breach of security or unauthorized use of your Account.
</p>
      </div>
    </div>


     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">E. USER CONTENT<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We may now or in the future permit users to post, upload, transmit through, or otherwise make available on the Website (collectively, “submit”) messages, text, illustrations, files, images, graphics, photos, comments, sounds, music, videos, information, content, and/or other materials (“User Content”). Subject to the rights and license you grant herein, you retain all right, title and interest in your User Content. We do not guarantee any confidentiality with respect to User Content even if it is not published on the Website. It is solely your responsibility to monitor and protect any intellectual property rights that you may have in your User Content, and we do not accept any responsibility for the same.
You shall not submit any User Content protected by copyright, trademark, patent, trade secret, moral right, or other intellectual property or proprietary right without the express permission of the owner of the respective right. You are solely liable for any damage resulting from your failure to obtain such permission or from any other harm resulting from User Content that you submit.
You represent, warrant, and covenant that you will not submit any User Content that:
•violates or infringes in any way upon the rights of others, including, but not limited to, any copyright, trademark, patent, trade secret, moral right, or other intellectual property or proprietary right of any person or entity;
•affects us adversely or reflect negatively on us, the Website, our goodwill, name or reputation or cause duress, distress or discomfort to us or anyone else, or discourages any person, firm or enterprise from using all or any portion, features or functions of the Website, or from advertising, linking or becoming a supplier to us in connection with the Website;
•impersonates another or is unlawful, threatening, abusive, libelous, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane, pornographic, or otherwise objectionable;
•encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law;
•is an advertisement for goods or services or a solicitation of funds;
•includes personal information such as messages which identify phone numbers, social security numbers, account numbers, addresses, or employer references;
•contains a formula, instruction, or advice that could cause harm or injury; or
•results in the transmission of junk e-mail, chain letters, duplicative or unsolicited messages, or so-called “spamming” and “phishing.”
Moreover, any conduct by a user that in our sole discretion restricts or inhibits any other user from using or enjoying the Website will not be permitted.
By submitting User Content to us, simultaneously with such submission you automatically grant, or warrant that the owner has expressly granted, to us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, fully sublicensable, and transferable right and license to use, reproduce, distribute, create derivative works based upon (including, without limitation, translations), publicly display, publicly perform, transmit, and publish the User Content (in whole or in part) as we, in our sole discretion, deem appropriate including, without limitation, (1) in connection with our business; and (2) in connection with the businesses of our strategic partners as well as the owners of hotels, casinos, residences, restaurants, and nightlife destinations (“Venues”) and their respective parents, subsidiaries, affiliates and other related companies that we manage but do not own, the owners and operators of licensed Venues, and the owners and operators of certain residential properties associated with or located in proximity to various Venues (as well as the respective parents, subsidiaries, affiliates, related companies, and designees of any of the foregoing) (collectively, “Property Partners”). We may exercise this grant in any format, media or technology now known or later developed for the full term of any copyright or other intellectual property right that may exist in such User Content. Furthermore, you also grant other users permission to access your User Content and to use, reproduce, distribute, create derivative works based upon, publicly display, publicly perform, transmit, and publish your User Content for personal, non-commercial use as permitted by the functionality of the Website and these Terms of Use.
By submitting User Content, you also grant us the right, but not the obligation to use your biographical information including, without limitation, your name and geographical location in connection with broadcast, print, online, or other use or publication of your User Content. You waive any and all claims you may now or later have in any jurisdiction to so-called “moral rights” or rights of “droit moral” with respect to the User Content.
We reserve the right to display advertisements in connection with your User Content and to use your User Content for advertising and promotional purposes. You acknowledge and agree that your User Content may be included on the websites and advertising networks of our distribution partners and third-party service providers (including their downstream users).
We have the right, but not the obligation, to monitor User Content. We have the right in our sole discretion and for any reason whatsoever to edit, refuse to post, remove, or disable access to any User Content.
</p>
      </div>
    </div>


         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">F. ADVERTISING<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">From time to time, you may communicate with, receive communications from, be re-directed to, interact with, or participate in or use the services or obtain goods and services of or from, third parties (collectively, the “Advertisers”) such as our advertisers, sponsors, or promotional partners as a result of your use of the Website. All such communication, interaction and participation is strictly and solely between you and such Advertisers and we shall not be responsible or liable to you in any way in connection with these activities or transactions (including, without limitation, any representations, warranties, covenants, contracts or other terms or conditions that may exist between you and the Advertiser or any goods or services you may purchase or obtain from any Advertiser).</p>
      </div>
    </div>


         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">G. E-COMMERCE<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">All features, content, specifications, products and prices of products and services described or depicted on the Website are subject to change at any time without notice. Certain weights, measures and similar descriptions are approximate and are provided for convenience purposes only. We make all reasonable efforts to accurately display the attributes of our products, including the applicable colors; however, the actual color you see will depend on your computer system, and we cannot guarantee that your computer will accurately display such colors. The inclusion of any products or services on the Website at a particular time does not imply or warrant that these products or services will be available at any time. It is your responsibility to ascertain and obey all applicable local, state, federal and international laws (including minimum age requirements) in regard to the possession, use and sale of any item purchased from the Website. By placing an order, you represent that the products ordered will be used only in a lawful manner. All videocassettes, DVDs and similar products sold are for private, home use (where no admission fee is charged), non-public performance and may not be duplicated. You may be presented with additional terms related to a specific purchase before you confirm the transaction (such as shipping terms for tangible goods). Those additional terms will also govern that transaction.
When an order is placed, it will be shipped to an address designated by the purchaser as long as that shipping address is compliant with the shipping restrictions contained on the Website. All purchases from this Website are made pursuant to a shipment contract. As a result, risk of loss and title for items purchased from the Website pass to you upon delivery of the items to the carrier. You are responsible for filing any claims with carriers for damaged and/or lost shipments.
We attempt to ensure that information on the Website is complete, accurate and current. Despite our efforts, the information on the Website may occasionally be inaccurate, incomplete or out of date. We make no representation as to the completeness, accuracy or currentness of any information on the Website. For example, products included on the Website may be unavailable, may have different attributes than those listed or may actually carry a different price than that stated on the Website. In addition, we may make changes in information about price and availability without notice. While it is our practice to confirm orders by email, the receipt of an email order confirmation does not constitute our acceptance of an order or our confirmation of an offer to sell a product or service. We reserve the right, without prior notice, to limit the order quantity on any product or service and/or to refuse service to any customer. We also may require verification of information prior to the acceptance and/or shipment of any order.
This Website may allow you to make payments using a number of different payment sources, like credit cards and debit cards. When you provide a payment source to us, you confirm that you are permitted to use that payment source. You also authorize us to collect and store it, along with other related transaction information. When you make a payment, you authorize us (and our designated payment processor) to charge the full amount to the payment source you designate for the transaction. If you pay by credit or debit card, we may obtain a pre-approval from the issuer of the card for an amount up to the amount of the purchase. We will bill your card at the time of purchase or shortly thereafter. If you cancel a transaction before completion, that pre-approval may result in your funds not otherwise being immediately available. If you pay by debit card and your payment results in an overdraft or other fee from your bank, you alone are responsible for that fee.
If you believe that an unauthorized or otherwise problematic transaction has taken place under your account, you agree to notify us immediately so that we may take action to attempt to prevent financial loss. To the fullest extent permitted by law, you waive all claims against us related to payments unless you submit the claim to us within 30 days after the charge. You are responsible for and agree to reimburse us for all reversals, charge-backs, claims, fees, fines, penalties and other liability incurred by us (including costs and related expenses) that were caused by or arising out of payments that you authorized or accepted.
Your only remedy for a technical failure or interruption of service is to request that your transaction be completed at a later time.
</p>
      </div>
    </div>


         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">H. CONTESTS, SWEEPSTAKES, AND PROMOTIONS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">From time to time, we, or our service providers, suppliers, Advertisers, and other third parties may conduct promotions on or through the Website, including, without limitation, contests and sweepstakes (“Promotions”). Each Promotion may have official rules which will be posted or otherwise made available to you and, for purposes of each Promotion, will be deemed incorporated into and form a part of this Agreement.</p>
      </div>
    </div>


         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">I. WEBSITE CONTENT & THIRD PARTY LINKS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We provide the Website including, without limitation, Website Content for informational, educational, entertainment and promotional purposes only. You may not rely on any information and opinions expressed on any of our Website for any other purpose. In all instances, it is your responsibility to evaluate the accuracy, timeliness, completeness, or usefulness of Website Content. Under no circumstances will we be liable for any loss or damage caused by your reliance on any Website Content.
In many instances, Website Content will include content posted by a third-party or will represent the opinions and judgments of a third-party. We do not endorse, warrant and are not responsible for the accuracy, timeliness, completeness, or reliability of any opinion, advice, or statement made on the Website by anyone other than authorized employees or spokespersons while acting in their official capacities.
If there is a dispute between persons accessing the Website or between persons accessing the Website and any third party, you understand and agree that we are under no obligation to become involved. If there is such a dispute, you hereby release the Website, us, and our respective officers, directors, employees, partners, agents, licensors, service providers, strategic partners, Property Partners, and distribution partners,, and each of their related companies (collectively, the “Indemnified Entities”) from claims, demands, and damages of every kind or nature arising out of, relating to, or in any way connected with such dispute.
The Website may contain links to other websites maintained by third parties. We do not operate or control, in any respect, or necessarily endorse the content found on these third-party websites. You assume sole responsibility for your use of third-party links. We are not responsible for any content posted on third-party websites or liable to you for any loss or damage of any sort incurred as a result of your dealings with any third-party or their website.
</p>
      </div>
    </div>

             <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">J. INDEMNIFICATION<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">You agree to indemnify and hold harmless the Indemnified Entities from and against any and all claims, liabilities, losses, damages, obligations, costs and expenses (including reasonable attorneys’ fees and costs) arising out of, related to, or that may arise in connection with: (i) your access to or use of the Website; (ii) User Content provided by you or through use of your Account; (iii) any actual or alleged violation or breach by you of these Terms of Use; (iv) any actual or alleged breach of any representation, warranty, or covenant that you have made to us; or (v) your acts or omissions. You agree to cooperate fully with us in the defense of any claim that is the subject of your obligations hereunder.</p>
      </div>
    </div>

             <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">K. DISCLAIMERS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. ALL PRODUCTS AND SERVICES PURCHASED ON OR THROUGH THE WEBSITE ARE SUBJECT ONLY TO ANY APPLICABLE WARRANTIES OF THEIR RESPECTIVE MANUFACTURERS, DISTRIBUTORS AND SUPPLIERS, IF ANY. THE WEBSITE AND WEBSITE CONTENT AND ALL PRODUCTS AND SERVICES PURCHASED ON OR THROUGH THE WEBSITE ARE PROVIDED BY US ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE INDEMNIFIED ENTITIES DISCLAIM ANY AND ALL WARRANTIES INCLUDING ANY: (1) WARRANTIES THAT THE WEBSITE, WEBSITE CONTENT, OR ANY PRODUCTS OR SERVICES PURCHASED ON OR THROUGH THE WEBSITE WILL MEET YOUR REQUIREMENTS; (2) WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, SECURITY, USEFULNESS, TIMELINESS, OR INFORMATIONAL CONTENT OF THE WEBSITE, WEBSITE CONTENT, OR ANY PRODUCTS OR SERVICES PURCHASED ON OR THROUGH THE WEBSITE; (3) WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE; (4) WARRANTIES FOR SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON OUR WEBSITE OR ACCESSED THROUGH THE WEBSITE; (5) WARRANTIES CONCERNING THE ACCURACY OR RELIABILITY OF THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE; (6) WARRANTIES THAT YOUR USE OF THE WEBSITE WILL BE SECURE OR UNINTERRUPTED; AND (7) WARRANTIES THAT ERRORS IN THE WEBSITE WILL BE CORRECTED.</p>
      </div>
    </div>

             <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">L. LIMITATION ON LIABILITY<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">UNDER NO CIRCUMSTANCES SHALL THE INDEMNIFIED ENTITIES BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF THE ABOVE LISTED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH THE WEBSITE OR THESE TERMS OF USE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE INCLUDING, WITHOUT LIMITATION, THE WEBSITE CONTENT IS TO STOP USING THE WEBSITE. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF SERVICES OR PRODUCTS RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH ANY OF THE WEBSITE OR ANY LINKS ON THE WEBSITE, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH ANY OF THE WEBSITE OR ANY LINKS ON THE WEBSITE. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF ANY CONTENT POSTED BY A THIRD-PARTY OR CONDUCT OF A THIRD-PARTY ON THE WEBSITE.
NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE CUMULATIVE LIABILITY OF THE INDEMNIFIED ENTITIES EXCEED THE GREATER OF THE TOTAL PAYMENTS RECEIVED FROM YOU BY THE INDEMNIFIED ENTITIES DURING THE PRECEDING TWELVE (12) MONTH PERIOD OR $100. FURTHERMORE, YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH ANY OF THE WEBSITE OR THESE TERMS OF USE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.
In some jurisdictions limitations of liability are not permitted. In such jurisdictions, some of the foregoing limitations may not apply to you. These limitations shall apply to the fullest extent permitted by law.
</p>
      </div>
    </div>


             <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">M. TERMINATION<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We reserve the right in our sole discretion and at any time to terminate or suspend your Account and/or block your access to the Website for any reason including, without limitation if you have failed to comply with the letter and spirit of these Terms of Use. You agree that the Indemnified Entities shall not be liable to you or any third party for any termination or suspension of your Account or for blocking your access to the Website.
Any suspension or termination shall not affect your obligations to us under these Terms of Use. The provisions of these Terms of Use which by their nature should survive the suspension or termination of your Account or these Terms of Use shall survive including, but not limited to the rights and licenses that you have granted hereunder, indemnities, releases, disclaimers, limitations on liability, provisions related to choice of law, dispute resolution, no class action, no trial by jury and all of the miscellaneous provisions set forth below.
</p>
      </div>
    </div>

         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">N. COPYRIGHT POLICY<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We respect the intellectual property rights of others and expect users to do the same. In appropriate circumstances and at our sole discretion, we may terminate and/or disable the Account of users suspected to be infringing the copyrights (or other intellectual property rights) of others. Additionally, in appropriate circumstances and in our sole discretion, we may remove or disable access to material on any of our websites or hosted on our systems that may be infringing or the subject of infringing activity.
In accordance with the Digital Millennium Copyright Act of 1998, Title 17 of the United States Code, Section 512 (“DMCA”), we will respond promptly to claims of copyright infringement that are reported to the agent that we have designated to receive notifications of claims infringement (its “Designated Agent”). Our Designated Agent may be reached via email at: copyright@sbe.com, and via regular mail and facsimile at: Copyright Agent, Creating Culinary Communities, LLC and SBE Restaurant Group, LLC d/b/a Disruptive Restaurant Group, 9247 Alden Drive, Beverly Hills, CA 90210, Attn: General Counsel, fax number: (323) 655-8001.
If you are a copyright owner (or authorized to act on behalf of the copyright owner) and have a good faith belief that your work’s copyright has been infringed, please report your notice of infringement to us by providing our Designated Agent with a written notification of claimed infringement that includes substantially the following:
•A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
•Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.
•Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, such as a specific URL address.
•Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an electronic mail address at which you may be contacted.
•A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
•A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
Please note that Section 512(f) of the Digital Millennium Copyright Act may impose liability for damages on any person who knowingly sends meritless notices of infringement. Please do not make false claims.
Any information or correspondence that you provide to us may be shared with third parties, including the person who provided us with the allegedly infringing material.
Upon receipt of a bona fide infringement notification by the Designated Agent, it is our policy to remove or disable access to the infringing material, notify the user that it has removed or disabled access to the material, and, for repeat offenders, to terminate such user’s access to the service.
If you believe that your content should not have been removed for alleged copyright infringement, you may send our Designated Agent a written counter-notice with the following information:
•Identification of the copyrighted work that was removed, and the location on this Website where it would have been found prior to its removal;
•A statement, under penalty of perjury, that you have a good faith belief that the content was removed as a result of a mistake or misidentification; and
•Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).
If a counter-notice is received by the Designated Agent, we may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the user, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at our discretion.
</p>
      </div>
    </div>


         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">O. ARBITRATION AND DISPUTE RESOLUTION AGREEMENT<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">PLEASE READ THIS FOLLOWING CLAUSE CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
Initial Dispute Resolution. We are available by email at privacy@sbe.com to address any concerns you may have regarding your use of the Website. Most concerns may be quickly resolved in this manner. We and you (each a “party” and together, the “parties”) shall use best efforts to settle any dispute, claim, question or disagreement directly through consultation and good faith negotiations which shall be a precondition to either party initiating a lawsuit or arbitration.
Agreement to Binding Arbitration. If the parties do not reach an agreed upon solution within a period of thirty (30) days from the time informal dispute resolution is pursued pursuant to Section O(1) above, then either party may initiate binding arbitration. All claims arising out of or relating to this agreement (including its formation, performance and breach), the parties’ relationship with each other and/or you use of the Website shall be finally settled by binding arbitration administered by the American Arbitration Association on a confidential basis in accordance with the provisions of its Commercial Arbitration Rules and the supplementary procedures for consumer related disputes of the American Arbitration Association (the “AAA”), excluding any rules or procedures governing or permitting class actions. The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of this agreement, including but not limited to any claim that all or any part of this agreement is void or voidable. The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator’s award shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. The interpretation and enforcement of this agreement shall be governed by the Federal Arbitration Act.
THE AAA’S RULES GOVERNING THE ARBITRATION MAY BE ACCESSED AT WWW.ADR.ORG OR BY CALLING THE AAA AT 1.800.778.7879. TO THE EXTENT THE FILING FEE FOR THE ARBITRATION EXCEEDS THE COST OF FILING A LAWSUIT, WE WILL PAY THE ADDITIONAL COST. A REQUEST FOR PAYMENT OF FEES SHOULD BE SUBMITTED TO AAA ALONG WITH YOUR FORM FOR INITIATING THE ARBITRATION, AND WE WILL MAKE ARRANGEMENTS TO PAY ALL NECESSARY FEES DIRECTLY TO THE AAA. IF THE ARBITRATOR FINDS THE ARBITRATION TO BE NON-FRIVOLOUS, WE WILL PAY ALL OF THE ACTUAL FILING AND ARBITRATOR FEES FOR THE ARBITRATION, PROVIDED YOUR CLAIM DOES NOT EXCEED $75,000. THE ARBITRATION RULES ALSO PERMIT YOU TO RECOVER ATTORNEYS’ FEES IN CERTAIN CIRCUMSTANCES.
THE PARTIES UNDERSTAND THAT, ABSENT THIS MANDATORY PROVISION, THEY WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. THEY FURTHER UNDERSTAND THAT, IN SOME INSTANCES, THE COSTS OF ARBITRATION COULD EXCEED THE COSTS OF LITIGATION AND THE RIGHT TO DISCOVERY MAY BE MORE LIMITED IN ARBITRATION THAT IN COURT.
Class Action and Class Arbitration Waiver. The parties further agree that any arbitration shall be conducted in their individual capacities only and not as a class action or other representative action, and the parties expressly waive their right to file a class action or seek relief on a class basis. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above in section O(2) shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.
Exceptions – Intellectual Property Claims. Notwithstanding the parties’ agreement to resolve all disputes through arbitration, either party may seek relief under law or in equity (including without limitation preliminary or other injunctive relief) in any court of competent jurisdiction for disputes or claims arising out of the alleged infringement of either party’s intellectual property rights.
Exceptions – Small Claims Court Claims. Notwithstanding the parties’ agreement to resolve all disputes through arbitration, either party may seek relief in small claims court for disputes or claims within the scope of that court’s jurisdiction.
30 Day Opt Out. You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth in sections O(2), O(3), O(4) and O(5) by sending written notice of your decision to opt-out to the following address: Creating Culinary Communities, LLC, SBE Restaurant Group and LLC d/b/a Disruptive Restaurant Group, 9247 Alden Drive, Beverly Hills, CA 90210, Attn: General Counsel. The notice must be sent within thirty (30) days of registering to use the Website, otherwise you shall be bound to arbitrate disputes in accordance with the terms of those sections. If you opt-out of these arbitration provisions, we also will not be bound by them.
Exclusive Venue for Litigation. To the extent that the arbitration provisions set forth in section O(2) do not apply, the parties agree that any litigation between them shall be filed exclusively in state or federal courts located in State of California, County of Los Angeles (except for small claims court actions which may be brought in the county where you reside, provided that we are subject to jurisdiction there). The parties expressly consent to exclusive jurisdiction in the State of California, County of Los Angeles for any litigation other than small claims court actions. The parties also expressly agree that, to the extent that the arbitration provisions set forth in section O(2) do not apply, these Terms of Use shall be construed in accordance with the laws of the State of California without regard to its conflict of laws rules that would result in the application of the laws of a jurisdiction other than the State of California.
</p>
      </div>
    </div>

         <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">P. AMENDMENT; ADDITIONAL TERMS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">We may modify or change these Terms of Use in the future. In addition, we reserve the right to provide you with operating rules or additional terms that may govern your use of the Website generally, unique parts of the Website, or both (“Additional Terms”). Any Additional Terms that we may provide to you are hereby incorporated by reference into these Terms of Use. To the extent any Additional Terms conflict with these Terms of Use, the Additional Terms will control.
You can identify the date that these Terms of Use were last modified by looking at the effective date at the top of this document. Any modifications to these Terms of Use will take effect upon posting to the Website. We will notify you of any material changes to these Terms of Use either by sending an email to the email address that you provided to us (if any) and/or by placing a notice on the homepage of the Website. It is your responsibility to review the Terms of Use and the Website from time to time for any changes or Additional Terms, and we encourage you to regularly review the current version of these Terms of Use on the Website. Your access and use of any of the Website following any modification of these Terms of Use or the provision of Additional Terms will signify your assent to and acceptance of the same. If you object to any subsequent revision to the Terms of Use or to any Additional Terms, immediately discontinue use of the Website and, if applicable, terminate your Account.
We reserve the right in our sole discretion and at any time and for any reason, to discontinue any aspect or feature of the Website.
</p>
      </div>
    </div>

     <div class="ExpandBlock_expandBlockWrapper__p6f5Q MuiBox-root css-0">
      <div class="ExpandBlock_expandBlock__7Nnw3">Q. MISCELLANEOUS<div>
          <div class="ExpandBlock_expandBtnSquare__mcmRo">
            <span class="ExpandBlock_expandClose__lv0yw"></span>
          </div>
        </div>
      </div>
      <div class="ExpandBlock_expandBlockContentWrapper__fx3Rk">
        <p class="ExpandBlock_expandBlockContent__N5vhS">No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall not be given any legal import. If any provision of these Terms of Use is held to be invalid or unenforceable, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms of Use, which shall remain in full force and effect.
These Terms of Use (including the Privacy Policy and any Additional Terms) constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede all previous written or oral agreements between us with respect to such subject matter.
You may not assign these Terms of Use or assign any rights or delegate any obligations hereunder, in whole or in part, without our prior written consent. Any such purported assignment or delegation by you without the appropriate prior written consent will be null and void and of no force and effect. We may assign these Terms of Use or any rights hereunder without your consent and without notice.
</p>
      </div>
    </div>






  </div>
</template>
<script>
import $ from 'jquery';
export default{
  mounted(){
    $('.ExpandBlock_expandBtnSquare__mcmRo').click(function(){
        if($(this).hasClass("ExpandBlock_expandOnClickRotate__V0ofh")==true){
            $(this).removeClass('ExpandBlock_expandOnClickRotate__V0ofh');
               if($(this).parent().parent().parent(".ExpandBlock_expandBlockWrapper__p6f5Q > .ExpandBlock_expandBlockContentWrapper__fx3Rk")){
                   $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').removeClass('ExpandBlock_expanded__o57uc');
               }
       }else{
       	 $(this).addClass('ExpandBlock_expandOnClickRotate__V0ofh');
       	 $(this).parent().parent().siblings('.ExpandBlock_expandBlockContentWrapper__fx3Rk').addClass('ExpandBlock_expanded__o57uc');
       }
    });
  }

}
</script>