<template>
	<span>{{this.cartItemCount}}</span>
</template>
<script>
export default{

  computed:{
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
    },

}
</script>