<template>
 <div id="listing-product-list">
      <div  id="listing-product-list-category" class="listing-nav-container-body">
              <div class="container-fluid custom-container">
                  <div class="col-lg-12 col-md-12">
                          <div class="row">
                                <div class="productdescription">
                                      <div class="imagearea">
                                              <img :src="'/images/menuitemimages/'+this.product.imagename">
                                      </div>
                                         <div class="productinfo">
                                             <h4><a style="cursor:pointer;" :href="url" ><i class="fa fa-arrow-left" aria-hidden="true"></i>  Back to Menu</a></h4>
                                             <h1>{{this.product.name}}</h1>
                                             <p v-html="this.product.description"></p>
                                                                                        
                                            <p class="food-price-p custom-design">
                                            <span class="discount-price">$ {{this.product.unit_price}}</span>
                                              
                                            </p>
                                            <p class="food-price-p custom-design">
                                              $ {{this.product.unit_price - this.product.discount_price}}
                                            </p>
                                           <div class="buttondiv">
                                            <a class="bagbutton" :href="url+'customize/'+this.product.id"> 
                                             <i class="fa fa-shopping-cart"></i> Customize
                                            </a>

                                            <a style="margin-left:10px;" @click.prevent="atddtocart()" class="bagbutton" href=""> 
                                             <i class="fa fa-shopping-cart"></i> Add to bag
                                            </a>
                                          </div>

                                         </div>
                                           
                                       </div>

                            </div>
                  </div>
            </div>
      </div>
 </div>

</template>
<style type="text/css">
    .productdescription{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:20px;

    }
    .buttondiv{
        position:absolute;
        bottom:0;
    }
    .bagbutton{
    text-align: center;
    background: #f91942;
    padding: 10px 0px;
    border-radius: 30px;
    width: 200px;
    display: inline-block;
    border: 3px solid black;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer; 
    color:white;
    }
    #listing-product-list{
        background:#fff;
    }
    .productdescription{
        margin-bottom:20px;
    }
    .productinfo{
        position:relative;
    }

    .imagearea img{
    max-width: 100%;
    border: 5px solid lightgray;
    }
</style>
<script>
export default{
  props:['productid'],
  data(){
  	return{
      product:[],
      menuitems:[],
      menutitle:'',
      menuitemsbag:[],
      calories:0,
      amount:[],
      quantity:1,
  	}
  },
  mounted(){
  	axios.get('/getmeuitem/'+this.productid).then(response=>{
      
        this.menuitems=response.data.menus;
        this.menutitle=response.data.menutitle;
        this.menuitemsbag=response.data.activemenu;
        this.calories=response.data.calories;
        this.amount=response.data.amount;
        this.product=response.data.product;
        if(this.amount==0){
          var price=this.product.unit_price - this.product.discount_price;
          this.amount=price;
        }

  	})

  },
    computed:{
        delveerycharge(){
           return this.$store.getters.delveerycharge; 
       },
        cart(){
          return this.$store.state.cart;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        },


    },
    methods:{

      atddtocart(){
         this.$store.dispatch('addProduct',{ 
         product:this.product,
         productoption:this.menuitemsbag,
         quantity:this.quantity,
         amount:this.amount,

         })

       }


    }

}
</script>