<template>
<div class="form-group">
  <div class="payment-tab registration-tab payment-tab-active">
    <div class="payment-tab-trigger">
       <input type="text"  ref="searchInput" placeholder="ENTER THE DELIVERY LOCATION" style="width:100%;display: block;">
    </div>
  </div>
    <div id="map"></div> 
</div>
</template>
<script>
import { ref, onMounted,watch,inject,defineProps} from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
export default {
  props:['restaurantlat','restaurantlong','charge_per_kilo','basic_delivery_charge','free_delivery_radius'],
  setup(props, context) {
    let map = ref(null);
    let marker = ref(null);
    let url = ref(null);
    let lat=ref(null);
    let long=ref(null);
    let address=ref(null);
    let state=ref(null);
    let city=ref(null);
    const searchInput = ref(null);
    const autocomplete = ref(null);
    const places = ref([]);
    const infoWindow = ref([]);
    const distance = ref(0);
    const store = useStore();
    const restaurantlat = props.restaurantlat;
    const restaurantlong = props.restaurantlong;
    const charge_per_kilo = props.charge_per_kilo;
    const basic_delivery_charge= props.basic_delivery_charge;
    const free_delivery_radius= props.free_delivery_radius;
    const app=this;
    onMounted(() => {
      const loader = new Loader({
        apiKey: 'AIzaSyCB0EkwqGYRfYtRsZkoGmrtsHGtWtSGuQE',
        version: 'weekly',
        libraries: ['places','geometry'],
      });
      loader.load().then(() => {
        map.value = new google.maps.Map(document.getElementById('map'), {
          center: { lat: 25.9476048, lng: -80.15129710000001 },
          zoom: 15,
        });
        marker.value = new google.maps.Marker({
          position: { lat: 25.9476048, lng: -80.15129710000001 },
          map: map.value,
          draggable: true,
        });
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            marker.value.setPosition(pos);
            map.value.setCenter(pos);

           }, function() {
            handleLocationError(true, infoWindow, map.getCenter());

          });   
    
          } else {
          // Browser doesn't support Geolocation
          handleLocationError(false, infoWindow, map.getCenter());
          }
         marker.value.addListener('dragend', () => {
          const position = marker.value.getPosition();
          url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+ position.lat() + ',' + position.lng() + '&sensor=false&key=AIzaSyCB0EkwqGYRfYtRsZkoGmrtsHGtWtSGuQE&callback=initMap&libraries=places';
          $.get(url, function(data) {
            if (data.status == 'OK') {
               Swal.fire({
                  title: 'Do you want to save the changes?',
                  showDenyButton: true,
                  showCancelButton: true,
                  confirmButtonText: 'Save',
                  denyButtonText: `Don't save`,
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    map.value.setCenter(data.results[0].geometry.location); 
                    address.value=data.results[0].formatted_address;
                    lat.value=data.results[0].geometry.location.lat;
                    long.value=data.results[0].geometry.location.lng;


                    var city = getCityFromFormattedAddress(address.value);
                    var newaddress = getAddressFromFormattedAddress(address.value)
                    var state = getStateFromFormattedAddress(address.value);
                    var postalCode = getPostalCodeFromFormattedAddress(data.results[0]);
                    
                    console.log('City:', city);
                    console.log('Address:', newaddress);
                    console.log('State:', state);
                    console.log('postalCode:', postalCode);

                    store.dispatch('Addaddress',{address:newaddress});
                    store.dispatch('Addcity',{city:city});
                    store.dispatch('Addstate',{newstate:state});
                    store.dispatch('Addpostalcode',{postalcode:postalCode});

                    var point1 = new google.maps.LatLng(restaurantlat, restaurantlong);
                    var point2 = new google.maps.LatLng(lat.value, long.value);
                    distance.value = google.maps.geometry.spherical.computeDistanceBetween(point1, point2)/ 1000;
                     var deliverycharge=0;
                     var totaldistance=Math.round(distance.value);
                     if(free_delivery_radius>=totaldistance){
                       deliverycharge=parseFloat(basic_delivery_charge);
                     }else{
                      var d=parseFloat(totaldistance)-parseFloat(free_delivery_radius);
                      var charge=parseFloat(charge_per_kilo)*parseFloat(d);
                      deliverycharge=parseFloat(basic_delivery_charge)+parseFloat(charge);
                      
                     }

                    

                     store.dispatch('deliverycharge',{deliverycharge:deliverycharge});
                     store.dispatch('totaldistance',{totaldistance:totaldistance});

                    Swal.fire('Saved!', '', 'success')
                  } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                  }
                });



            }
          })

        });
        autocomplete.value = new google.maps.places.Autocomplete(searchInput.value, {
        fields: ['place_id', 'name', 'formatted_address', 'geometry'],
        types: ['address'],
        });

        autocomplete.value.addListener('place_changed', () => {
        const place = autocomplete.value.getPlace();
        var   bounds = new google.maps.LatLngBounds();

        if (place.geometry) {
          places.value = [place];
        }
        });


      });
    });

    function selectPlace(place) {
      console.log(place);
      
    }


    function getCityFromFormattedAddress(formattedAddress) {
      const parts = formattedAddress.split(',');
      if (parts.length > 1) {
        return parts[parts.length - 2].trim();
      }
      return '';
    }

    function getAddressFromFormattedAddress(formattedAddress) {
      const parts = formattedAddress.split(',');
      if (parts.length > 1) {
        return parts[0].trim();
      }
      return formattedAddress.trim();
    }

    function getStateFromFormattedAddress(formattedAddress) {
      const parts = formattedAddress.split(',');
      if (parts.length > 1) {
        return parts[parts.length - 2].trim();
      }
      return '';
    }

    function getPostalCodeFromFormattedAddress(formattedAddress) {
      const addressComponents = formattedAddress.address_components;
      const postalCodeComponent = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      return postalCodeComponent ? postalCodeComponent.long_name : '';
    }


    watch(
      () => places.value,
      (newPlaces) => {
        if (newPlaces.length) {

         var latvalue=newPlaces[0].geometry.location.lat();
         var lngvalue=newPlaces[0].geometry.location.lng();
          map.value = new google.maps.Map(document.getElementById('map'), {
          center: { lat: latvalue, lng: lngvalue },
          zoom: 15,
          });
          marker.value = new google.maps.Marker({
          position: { lat: latvalue, lng: lngvalue },
          map: map.value,
          draggable: true,
          });

          marker.value.addListener('dragend', () => {
          const position = marker.value.getPosition();
          url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+ position.lat() + ',' + position.lng() + '&sensor=false&key=AIzaSyCB0EkwqGYRfYtRsZkoGmrtsHGtWtSGuQE&callback=initMap&libraries=places';
          $.get(url, function(data) {
            if (data.status == 'OK') {
                 Swal.fire({
                  title: 'Do you want to save the changes?',
                  showDenyButton: true,
                  showCancelButton: true,
                  confirmButtonText: 'Save',
                  denyButtonText: `Don't save`,
                  }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    map.value.setCenter(data.results[0].geometry.location); 
                    address.value=data.results[0].formatted_address;
                    lat.value=data.results[0].geometry.location.lat;
                    long.value=data.results[0].geometry.location.lng;
                    const latLng = new google.maps.LatLng(lat.value, long.value);

                    var city = getCityFromFormattedAddress(address.value);
                    var newaddress = getAddressFromFormattedAddress(address.value)
                    var state = getStateFromFormattedAddress(address.value);
                    var postalCode = getPostalCodeFromFormattedAddress(data.results[0]);
                    
                    console.log('City:', city);
                    console.log('Address:', newaddress);
                    console.log('State:', state);
                    console.log('postalCode:', postalCode);

                    store.dispatch('Addaddress',{address:newaddress});
                    store.dispatch('Addcity',{city:city});
                    store.dispatch('Addstate',{newstate:state});
                    store.dispatch('Addpostalcode',{postalcode:postalCode});

                    var point1 = new google.maps.LatLng(restaurantlat, restaurantlong);
                    var point2 = new google.maps.LatLng(lat.value, long.value);
                    distance.value = google.maps.geometry.spherical.computeDistanceBetween(point1, point2)/ 1000;
                     var deliverycharge=0;
                     var totaldistance=Math.round(distance.value);
                     if(free_delivery_radius>=totaldistance){
                       deliverycharge=parseFloat(basic_delivery_charge);
                     }else{
                      var d=parseFloat(totaldistance)-parseFloat(free_delivery_radius);
                      var charge=parseFloat(charge_per_kilo)*parseFloat(d);
                      deliverycharge=parseFloat(basic_delivery_charge)+parseFloat(charge);

                     }

                     store.dispatch('deliverycharge',{deliverycharge:deliverycharge});
                     store.dispatch('totaldistance',{totaldistance:totaldistance});

                    

                    Swal.fire('Saved!', '', 'success')
                  } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                  }
                });

            }
          })

        });


        }
      }
    );
    return {
      map,
      marker,
      lat,
      long,
      address,
      searchInput,
      places,
      selectPlace,
      infoWindow,
      distance,
      restaurantlat,
      restaurantlong,
      charge_per_kilo,
      basic_delivery_charge,
      free_delivery_radius,
      state,
      city,

    };
  }
};
</script>
<style>
  html,
  body,
  #map {
    height:;
    margin: 0;
    padding: 0;
    width: ;
  }
</style>

