import createPersistedState from "vuex-persistedstate";
export default {
  state (){
    return {
      cart:[],
      url:"https://www.ezlunchonline.com/",
      username:'',
      maintotal:0,
      address:'',
      deliverycharge:0,
      totaldistance:0,
      city:'',
      newstate:'',
      postalcode:'',
      userapt:'',
    }
 },
 getters: {
    cartItemCount(state) {
            return state.cart.length;
    },
    cartTotalPrice(state){
            let total =0;
            state.cart.forEach(item=>{
               total =total+parseFloat(item.amount); 
            })

            return total;
    },
    username(state){

       return state.username; 

    },
    address(state){
       return state.address; 
    },
    addstate(state){
       return state.newstate; 
    },
    addcity(state){
       return state.city; 
    },
    adduserapt(state){
      return state.userapt; 
    },
    addpostalcode(state){
       return state.postalcode; 
    },
    deliverycharge(state){
       return state.deliverycharge; 
    },
    totaldistance(state){
       return state.totaldistance; 
    }

 },
  mutations:{
        ADD_TOCART(state,{product,productoption,quantity,amount}){
            let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            
            if(productInCart){
                productInCart.quantity =quantity;
                productInCart.amount =parseFloat(amount)*parseInt(quantity);
                return;
            }
            state.cart.push({
                product,
                productoption,
                quantity,
                amount
                

            })

        },
        REMOVE_TOCART(state,{product,quantity}){
            let productInCart=state.cart.find(item=>{
                return item.product.id===product.id; 
            });
            if(productInCart){
                productInCart.quantity -=quantity;
                return;
            }
            state.cart.pop({
                product,
                quantity
            })

        },
        REMOVE_PRODUCT_FROM_CART(state,product){
            state.cart=state.cart.filter(item=>{
                return item.product.id !==product.id;
            })
        },
        CLEAR_CART_ITEMS(state){
           state.cart=[];
        },
        ADD_USER(state,username){
           state.username=username;
        },
        ADD_ADDRESS(state,address){
           state.address=address;
        },
        ADD_CITY(state,city){
           state.city=city;
        },
        ADD_STATE(state,newstate){
           state.newstate=newstate;
        },
        ADD_POSTALCODE(state,postalcode){
           state.postalcode=postalcode;
        },
        ADD_APT(state,userapt){
           state.userapt=userapt;
        },
        ADD_DELIVERYCHARGE(state,deliverycharge){
           state.deliverycharge=parseFloat(deliverycharge.deliverycharge);
        },

        ADD_TOTALDISTANCE(state,totaldistance){
             state.totaldistance=parseFloat(totaldistance.totaldistance);
        },
        UPDATE_MAINTOTAL(state,delveryamount){

           state.maintotal=parseFloat(delveryamount.delveryamount);
        },
        
      },
      actions: {

        SaveName:({ commit },{username}) =>{

            commit('ADD_USER', {username});

         },
         Addaddress:({ commit }, {address}) =>{

            commit('ADD_ADDRESS', {address});

         },
         Addcity:({ commit }, {city}) =>{

            commit('ADD_CITY', {city});

         },
         Addstate:({ commit }, {newstate}) =>{

            commit('ADD_STATE', {newstate});

         },
         Addpostalcode:({ commit }, {postalcode}) =>{

            commit('ADD_POSTALCODE', {postalcode});

         },
         Addapt:({ commit }, {userapt}) =>{

            commit('ADD_APT', {userapt});

         },
         deliverycharge:({ commit }, {deliverycharge}) =>{

            commit('ADD_DELIVERYCHARGE', {deliverycharge});

         },
        totaldistance:({ commit }, {totaldistance}) =>{

            commit('ADD_TOTALDISTANCE', {totaldistance});

         },
         Updatemaintotal:({ commit }, {delveryamount}) =>{

            commit('UPDATE_MAINTOTAL', {delveryamount});

         },

        addProduct: ({ commit }, {product,productoption,quantity,amount}) =>{

            commit('ADD_TOCART', {product,productoption,quantity,amount});

       },
       removeProductFromCart:({ commit }, product) =>{

            commit('REMOVE_PRODUCT_FROM_CART', product);


      },
      clearCartItems:({ commit }, product) =>{

          commit('CLEAR_CART_ITEMS');
      },



    },
    plugins:[createPersistedState()]
}










