<template>
 <div id="listing-product-list">
      <div  id="listing-product-list-category" class="listing-nav-container-body">
              <div class="container-fluid custom-container">
                <h2 class="category-style" style="text-align: center;" >{{this.categoryname}}</h2>
                  <div class="col-lg-12 col-md-12" v-for="item in  this.caloriyitems">
                          <div class="row">
                                <div class="productdescription" >
                                      <div class="imagearea">
                                            <img :src="'/images/menuitemimages/'+item.product.imagename">
                                      </div>
                                         <div class="productinfo">
                                             
                                             <h1>{{item.product.name}}</h1>
                                             <p v-html="item.product.description"></p>
                                                                                        
                                            <p class="food-price-p custom-design">
                                            <span class="discount-price">$ {{item.product.unit_price}}</span>
                                              
                                            </p>
                                            <p class="food-price-p custom-design">
                                              $ {{item.product.unit_price - item.product.discount_price}}
                                            </p>
                                           <div class="buttondiv">
                                            <a class="bagbutton" :href="url+'customize/'+item.product.id"> 
                                             <i class="fa fa-shopping-cart"></i> Customize
                                            </a>
                                            <a style="margin-left:10px;" @click.prevent="atddtocart(item.product,item.activemenu)" class="bagbutton" href=""> 
                                             <i class="fa fa-shopping-cart"></i> Add to bag
                                            </a>
                                          </div>

                                         </div>
                                           
                                </div>

                            </div>
                  </div>
            </div>
      </div>
 </div>

</template>
<style type="text/css">
    .productdescription{
        display:grid;
        grid-template-columns:1fr 2fr;
        grid-gap:20px;

    }
    .buttondiv{
        position:absolute;
        bottom:0;
    }
    .bagbutton{
    text-align: center;
    background: #f91942;
    padding: 10px 0px;
    border-radius: 30px;
    width: 200px;
    display: inline-block;
    border: 3px solid black;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer; 
    color:white;
    }
    #listing-product-list{
        background:#fff;
    }
    .productdescription{
        margin-bottom:20px;
    }
    .productinfo{
        position:relative;
    }

    .imagearea img{
     min-width:400px;
     max-width:400px;
     max-height:350px;
    border: 5px solid lightgray;
    }
</style>
<script>
export default{
  props:['categoryid'],
  data(){
  	return{
      caloriyitems:[],
      product:[],
      menuitems:[],
      menutitle:'',
      menuitemsbag:[],
      calories:0,
      amount:[],
      quantity:1,
      categoryname:'',
  	}
  },
  mounted(){
     
     axios.get('/getcategoryproduct/'+this.categoryid).then(response=>{

          this.caloriyitems=response.data.categoriproducts;
          this.categoryname=response.data.categoryname;

          console.log(this.caloriyitems);



     });


  },
    computed:{
        delveerycharge(){
           return this.$store.getters.delveerycharge; 
       },
        cart(){
          return this.$store.state.cart;
        },
        username(){
          return this.$store.getters.username;
        },
        cartItemCount(){
            return this.$store.getters.cartItemCount
        },
        totalprice(){
           return this.$store.getters.cartTotalPrice 
        },
        url(){
         return this.$store.state.url;
        },


    },
    methods:{

      atddtocart(product,productoption){

        console.log(productoption);

         var amount=product.unit_price - product.discount_price;
         this.$store.dispatch('addProduct',{ 
         product:product,
         productoption:productoption,
         quantity:this.quantity,
         amount:amount,

         })

       }


    }

}
</script>